// Tables

.table-container {
  max-width: 100%;
  overflow: auto;
  border: 1px solid @bordercolor;
  margin-bottom: @baselineheight;
}

// Tables
.event-table {
  margin-bottom: 0;
  th,
  td {
    padding: @baselineheight/4 1em;
    line-height: 1.2;
    vertical-align: middle;
  }
  th {
    font-family: @altfont;
    text-transform: uppercase;
    background-color: @darkergrey;
    color: @white;
  }
  td {
    .fluid-type(font-size, 320px, 1440px, 14px, 16px);
  }
}
td.event-table__title {
  width: 40%;
  .fluid-type(font-size, 320px, 1440px, 16px, 20px);
  font-family: @altfont;
  font-weight: bold;
  text-transform: uppercase;
  a {
    border: none;
  }
}

// CONDENSED ====================================================

.table-condensed {
  th,
  td {
    padding : 4px 5px;
  }
}

// BORDERED ====================================================

.table-bordered {
  border : @tableborderwidth @tableborderstyle @tableborder;
  border-left : 0;
  border-collapse : separate;
  *border-collapse : collapsed;
  th,
  td {
    border-left : @tableborderwidth @tableborderstyle @tableborder;
  }
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top : 0;
  }
}

// STRIPED ====================================================

.table-striped {
  tbody tr:nth-child(odd) td,
  tbody tr:nth-child(odd) th {
    background-color : @tablestripe;
  }
}
