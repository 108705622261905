// Images

// Alignment

.align-left {
  display: inline;
  float: left;
  margin-right: @baselineheight;
  margin-bottom: @baselineheight/2;
}
.align-right {
  display: inline;
  float: right;
  margin-left: @baselineheight;
  margin-bottom: @baselineheight/2;
}
.align-center {
  clear: both;
  display: block;
  margin: 0 auto @baselineheight/2;
}

