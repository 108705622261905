// Banners

.banner {
  margin: @baselineheight/2 @baselineheight/2 @baselineheight;
  padding: @baselineheight/1.5 4%;
  border: 1px solid @grey;
//  border-bottom-width: 6px;
  background: @lightestgrey;
  .fluid-type(font-size, 320px, 1440px, 16px, 20px);
  .clearfix;
}

@media only screen and (max-width: 39.9735em) {
  .banner__cta {
    text-align: center;
  }
}

@media only screen and (min-width: 40em) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    p,
    blockquote {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .banner__text {
    margin-right: 5%;
  }
  .banner__cta {
    text-align: right;
    margin-top: 0;
    flex-shrink: 0;
  }
  .no-flexbox & {
    .banner__text {
      float: left;
      width: 75%;
      margin-right: 2%;
    }
    .banner__cta {
      float: right;
      width: 23%;
    }
  }
}