#site-footer {
  margin-top: @baselineheight*2;
  padding: @baselineheight 0;
  background-color: @darkergrey;
  color: @white;
  .font-size(14);
  .fluid-type(font-size, 320px, 1440px, 14px, 15px);

  a:link,
  a:visited {
    color: @white;
  }
  a:hover,
  a:focus {
    color: @red;
  }
}

// the <a> around the logo
#uchicago-arts-signature {
  display: block;
  width: 33%;
  border: none;
  margin-bottom: @baselineheight;
  > svg {
    fill: @white;
    height: auto;
  }
  &:hover,
  &:focus {
    > svg {
      fill: #939597;
    }
  }
}

.social-links {
  &:extend(.list--plain);
  clear: both;
  li {
    display: inline-block;
    margin-right: .5em;
  }
}

.textwidget p {
  font-size: .9em;
}
#site-footer .social-links {
  a {
    display: block;
    border: none;
    color: @white;
    &:hover,
    &:focus {
      color: @red;
    }
  }
}

#email-address {
  width: 18em;
}

@media only screen and (min-width: 23em) {
  .social-links {
    float: right;
    clear: none;
  }
  .site-footer__contact address {
    float: right;
    margin-left: 3em;
    text-align: right;
  }
}

@media only screen and (min-width: @column-breakpoint-med) {
  .social-links {
    float: none;
    clear: both;
  }
  .site-footer__contact {
    text-align: right;
  }
  #uchicago-arts-signature {
    width: 66%;
    margin-bottom: @baselineheight/2;
  }
}