@topbarheight: 50px;

.top-bar {
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 12px rgba(0,0,0,0.6);
  height: @topbarheight;
  background-color: @darkergrey;
  color: @white;
  width: 100%;
}
.top-bar__ctas {
  float: right;
  padding: 3px 4px;
  > .btn {
    font-size: 14px;
    padding: 12px 14px 10px;
    margin: 3px 1px;
  }
}

#nav-toggle {
  font-size: 14px;
  height: @topbarheight;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  background: transparent;
  color: @white;
  font-family: @altfont;
  text-transform: uppercase;
  .icon {
    vertical-align: -2.5px;
  }
}

#site-header {
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  max-width: 100%;
  background-color: @white;
}
#site-main {
  margin-top: @topbarheight;
}

.site-header__top {
  padding: @baselineheight/2 5% @baselineheight/4;
}

.site-logo {
  display: block;
  text-decoration: none;
  border: none;
  text-align: center;
  &:hover,
  &:focus {
    border: none;
    #court-square {
      fill: @darkred;
    }
  }
  > svg {
    max-width: 100%;
  }
  #court-square {
    fill: @red;
  }
  #court-bg {
    fill: @white;
  }
  #theatre {
    fill: @black;
  }
  #site-header & {
    margin: 0 auto 10px;
    width: 200px;
    height: 108px;
  }
  .top-bar & {
    display: inline-block;
    vertical-align: top;
    width: 78px;
    height: 42px;
    padding: 4px 0;
    position: relative;
    #theatre {
      fill: @white;
    }
  }
}

.site-header__ctas {
  text-align: center;
  margin-top: @baselineheight/4;
  border-top: 1px solid @bordercolor;
  border-bottom: 1px solid @bordercolor;
  padding: @baselineheight/2 0;
}

.site-header__phone {
  text-align: center;
  text-transform: uppercase;
  .font-size(15);
  font-weight: bold;
  margin-bottom: 0;
  a {
    color: inherit;
    border: none;
  }
}

@media only screen and (max-width: 23em) {
  #nav-toggle {
    padding: 10px 8px 10px 10px;
  }
  .top-bar .site-logo {
    width: 60px;
    height: 35px;
    padding-top: 8px;
  }
  .top-bar__ctas > .btn {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (max-width: @nav-break-max) {
  
  :target:before {
    // this doesn't seem to work for some reason
    content:"";
    display:block;
    height: @topbarheight;
    margin: -@topbarheight 0 0;
  }
  #site-header {
    position: relative;
    top: @topbarheight;
  }
  .js #site-header {
    position: fixed;
    z-index: 102;
    width: 18em;
    transform: translateX(-18em);
    transition: transform ease-out .2s;
  }
  #site-header.opened {
    transform: translate(0);
    box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
  }
  .site-header__ctas > .btn {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (min-width: @nav-break) {
  .top-bar {
    display: none;
    visibility: hidden;
  }
  #site-header {
    width: 25%;
    box-shadow: none;
  }
  #site-header .site-logo {
    width: auto;
    height: auto;
    margin: 15px 0;
  }
  #site-main,
  #site-footer {
    width: 75%;
    margin-left: 25%;
    margin-top: 0;
  }
  .live-video {
    #site-main, #site-footer {
      width: 100%;
      // max-width: 1420px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      padding: 1em 30px;
    }
  }
  .site-header__phone {
    margin-bottom: @baselineheight/2;
  }
  .site-header__ctas {
    > .btn {
      .font-size(13);
      margin: 0 2px;
      padding: 9px;
    }
  }
}



@media only screen and (min-width: 65em) {
  .site-header__ctas > .btn {
    .font-size(15);
    padding: 10px 15px;
  }
}

