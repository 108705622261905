/* Type Classes */

.title {
  font-family: @altfont;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin-bottom: @baselineheight/4;
  background: transparent;
  color: @baseheadingfontcolor;
  padding: 0;
}

.subtitle {
  font-family: @baseheadingfont;
  .font-size(@h4size);
  line-height: 1.35;
}

.byline {
  color: @lighttextcolor;
}

.subheading {
  text-transform: uppercase;
  background-color: @darkgrey;
  color: @white;
  padding: 0.25em 0.5em 0.2em;
  letter-spacing: 0.5px;
  a:link,
  a:visited {
    color: saturate(tint(@red, 15%), 20%);
  }
  a:hover,
  a:focus {
    color: @red;
  }
}
.promo {
  text-transform: uppercase;
  margin-bottom: 0;
}
.top-billing {
  text-transform: uppercase;
  .font-size(16);
}
.meta {
  color: @lighttextcolor;
  .font-size(14);
  text-transform: uppercase;
  margin-bottom: @baselineheight/2;
}
.lesser {
  color: @lighttextcolor;
  .font-size(14);
  font-weight: normal;
}
.caption {
  color: @lighttextcolor;
  .font-size(12);
  line-height: 1.2;
}
.lead {
  .font-size(@h4size);
  line-height: 1.5;
}

.person__role {
  text-transform: uppercase;
  .font-size(14);
  line-height: 1.2;
}

// List Styles
.list--plain {
  padding-left: 0;
  li {
    list-style: none;
  }
}

.list--prominent {
  li {
    .font-size(@h5size);
    line-height: @baselineheight;
    margin-bottom: @baselineheight;
    &:last-child {
      margin-bottom: 0;
    }
  }
  li ul {
    margin-top: @baselineheight/2;
    li {
      .font-size(@h6size);
      margin-bottom: @baselineheight/2;
    }
  }
}

.list--comfortable {
  li {
    margin-bottom: @baselineheight/2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list--cozy {
  li {
    margin-bottom: @baselineheight/4;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list--bordered {
  li {
    padding-bottom: @baselineheight/2;
    border-bottom: 1px solid @lightestgrey;
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  li ul {
    li {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.list--columns {
  .columns(18em, 4, 2em, transparent, none, 0);
  > li {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
  }
}
.list--columns--narrow {
  &:extend(.list--columns all);
  column-width: 10em;
}

.list--inline {
  > li {
    display: inline;
    margin-right: 1em;
  }
}

// horizontal definition lists
.dl--horizontal {
  dt {
  float : left;
  clear : left;
  width : 20.25%;
  text-align : right; }
  
  dd {
  margin-left : 22.78%; } 
}

// blockquote
.blockquote  { 
  font-style: normal;
  padding: 0 1em;
  p,
  .quote {
    margin: 0 0 (@baselineheight/4);
  }
  .quote {
    &:before {
      content: '\201C';
    }
    &:after {
      content: '\201D';
    }
  }
  footer,
  .attr {
      &:before {
      content:'\2014\00A0';
    }
  }
}