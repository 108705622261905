.icon {
  display: inline-block;
  vertical-align: middle;
}

svg.icon {
  fill: currentColor;
}

// Sizes for SVG Icons
.icon--textsize {
  width: 1em;
  height: 1em;
}
.icon--small {
  width: 16px;
  height: 16px;
}
.icon--medium {
  width: 24px;
  height: 24px;
}
.icon--large {
  width: 32px;
  height: 32px;
}
.icon--xlarge {
  width: 48px;
  height: 48px;
}

// use this if using <i> element for icons
/*
i.icon {
  font-style: normal;
}
*/

// File Link Icons, for use with Font Awesome
/*
a[href$=".pdf"]:before {
  font-family: "FontAwesome";
  content: "\f1c1";
  margin-right: 0.25em;
}
a[href$=".doc"]:before {
  font-family: "FontAwesome";
  content: "\f1c2";
  margin-right: 0.25em;
}
a[href$=".docx"]:before {
  font-family: "FontAwesome";
  content: "\f1c2";
  margin-right: 0.25em;
}
a[href$=".xls"]:before {
  font-family: "FontAwesome";
  content: "\f1c3";
  margin-right: 0.25em;
}
a[href$=".xlsx"]:before {
  font-family: "FontAwesome";
  content: "\f1c3";
  margin-right: 0.25em;
}
a[href$=".ppt"]:before {
  font-family: "FontAwesome";
  content: "\f1c4";
  margin-right: 0.25em;
}
a[href$=".pptx"]:before {
  font-family: "FontAwesome";
  content: "\f1c4";
  margin-right: 0.25em;
}
a[href$=".jpg"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".jpeg"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".gif"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".png"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".txt"]:before {
  font-family: "FontAwesome";
  content: "\f0f6";
  margin-right: 0.25em;
}
*/