.purchase-confirmation {

    .section-header {
        flex-basis: 100%;
        width: 100%;
    }
    h5 {
        // color: #b8272d;
        font-size: 1.24em;
    }
    .label {
        font-weight: bold;
    }
    .confirm-header {
        margin-top: 1.6em;
        font-size: 16px;

        .purchase-info {
            ul {
                list-style-type: none;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                background-color: #eee;
                li {
                    padding: .6em;

                }
            }
            h4 {
                margin-top: 1.6em;
            }
        }
    }

    .confirm-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-top: 1.4em;
        font-size: 16px;

        .customer-info {
            flex-basis: 100%;
            margin-right: 0%;
            padding-right: 1.6em;
            ul {
                list-style-type: none;
                padding-left: 0;

                li {

                    &.address {
                        margin: 1.6em 0;

                        .label {
                            display: block;
                            
                        }
                        span {
                            display: block;
                        }
                    }
                }
            }
        }

        .email-info {
            ul {
                list-style-type: none;
                padding-left: 0;

                li {
                    margin-bottom: 1.6em;
                }
            }
        }
    }

    .performances {
        border-bottom: solid 1px #cfcfcf;
        border-top: solid 1px #cfcfcf;
        margin: .4em 0 1.6em;
        padding-left: 0;
        padding-right: 0;

        .performance-header {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            font-size: 16px;
            padding-top: 1.4em;

            .performance-details {

                h5 {
                    color: #b8272d;
                }
            }

        }
        .seats {
            list-style-type: none;
            padding-left: 0;
            font-size: 16px;
            max-width: 42em;
            margin: 1em 0 1.8em;
            display: flex;


            li {
                display: grid;
                // grid-template-columns: 15% 10% 10% 15% 35% 15%;
                grid-template-columns: repeat(1, 1fr);
                gap: 0px;
                margin: .1em 0;
                padding: .6em 0;
                line-height: 1.8em;

                &.seat-header {
                    font-weight: bold;
                    font-size: 14px;
                    text-align: right;
                    flex-shrink: 0;
                    padding-right: 2px;
                }
                .seats-inner {
                    display: flex;
                    flex-direction: row;
                    overflow-x: scroll;

                    li {
                        display: grid;
                        // grid-template-columns: 15% 10% 10% 15% 35% 15%;
                        grid-template-columns: repeat(1, 1fr);
                        gap: 0px;
                        margin: 0;
                        padding: 0 .6em;
                        margin-right: .6em;
                        

                        &.seat {
                            span {
                                padding: 0 .2em;
                            }
        
                            &:nth-child(odd) {
                                background-color: #efefef;
                            }
                        }
                    }
                }   
            }
        }
    }
    .confirmation-footer {
        font-size: 16px;

        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .payment-info {    
            ul {
                list-style-type: none;
                padding-left: 0;
            }
        
        }

        .charges-final {
            margin-top: 1.4em;
            ul {
                list-style-type: none;
                padding-left: 0;

                li:last-child {
                    display: inline-block;
                    margin-top: 1.6em;
                    padding-top: .4em;
                    border-top: solid 1px #dfdfdf;
                }
            }
        }
    }
}
@media (min-width: 540px) {
    .purchase-confirmation {
        .performances {
            .seats {
                display: block;

                li {
                    &:first-child {
                        grid-template-columns: 15% 10% 10% 15% 35% 15%;
                        padding: .2em .4em;
                    }
                    &.seat-header {
                        
                        font-size: 16px;
                        text-align: left;
                    }
                    .seats-inner {
                        padding-left: 0;
                        display: block;
                        overflow-x: visible;

                        li {   
                            grid-template-columns: 15% 10% 10% 15% 35% 15%;
                            margin-bottom: .4em;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .purchase-confirmation {
        .performances {
            padding-left: 1.5em;
            padding-right: 1.8em;
            
            .performance-header {
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }
    }
}
@media (min-width: 840px) {
    .purchase-confirmation {
        .confirm-header {

            .purchase-info {
                ul {
                    flex-direction: row;
                }
            }
        }
        .confirm-body {
            
            flex-wrap: wrap;
            flex-direction: row;
    
            .customer-info {
                flex-basis: 36%;
                flex-direction: row;
                margin-right: 2%;
                padding-right: 1.6em;
                margin-left: 1.8em;
            }
            .email-info {
                margin-right: 2em;
            }
        }
        .confirmation-footer {
            .charges-final, .payment-info {
                ul {
                    margin-left: 1.8em;
                }
            }
        }
    }   
}