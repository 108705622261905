html,
body {
  height: 100%;
}

.site_error_alert {
  position: absolute;
  z-index: 104;
  top: 30%;
  margin: auto auto;
  width: 400px;
  background: #fff;
  height: 400px;
  display: flex;
  align-items: center;
  padding: 2em;
  border: solid red;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 26px;
  box-sizing: border-box;
}

#courtTheatre_seatmap {
  background-image: url(/wp-content/themes/ct-2017/dist/images/map_background.jpeg);
  background-repeat: no-repeat;
  background-size: 98.3%;
  background-position: left -3px top 0;
}

.seat-select {
  display: block;
  max-width: 62em;
  margin: .2em auto .6em;
  position: relative;

  #zoomControls {
    position: absolute;
    z-index: 32;
    top: 12px;
    right: 12px;

    button {
      font-size: 12px;
      min-width: 32px;
      padding: 4px;
    }
  }
}

.seat-info {
  height: 5em;
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;

  .seatDesc {
    flex-basis: 33%;
  }

  .seatMssg {
    padding: 0 1em;
  }
}

.btn-primary {
  background-color: #B8272D;
  color: #fff;
  margin-right: 1.2em;

  &:hover {
    background-color: #931f24;
    color: #fff;
  }
}

span.error {
  color: #b8272d;
  font-weight: 600;
}

#seat_modal {
  background-color: #ededed;
  position: fixed;
  top: 12em;
  left: calc( 0px +  25% );
  right: 0;
  margin: 0 auto;
  max-width: 480px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  overflow: hidden;
  display: none;
  border-radius: 4px;
  z-index: 101;

  .seat-modal__close-modal {
    opacity: .6;
    width: 40px;
    box-sizing: border-box;
    display: block;
    height: auto;
    border: none;
    float: right;
    padding: 12px;
    fill: #cdcdcd;
  }

  h4 {
    margin: 1.4em .4em 1em;
  }

  .price-content {

    input[type='radio'] {
      opacity: 0;
      position: absolute;
      left: -300px;

      &:checked+label {
        background-color: #b8272d;
        color: #fff;
        font-weight: bold;
        letter-spacing: -.012em;
      }
    }

    label {
      background-color: #fff;
      font-size: 14px;
      border: solid 1px #ccc;
      margin: 4px auto;
      width: 100%;
      display: block;
      max-width: 460px;
      padding: 3px 12px;
      font-weight: normal;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;

      .dashicons.dashicons-tag {
        color: #b8272d;
        position: relative;
        top: 5px;
      }
    }
  }

  &.active {
    display: block;
  }

  button[type='submit'] {
    margin: .8em auto 1.2em;
    display: block;
    text-align: center;
  }

  .message {
    color: #b8272d;
    text-align: center;
    font-size: 16px;
  }
}

#av_bestAvail {
  margin: 1.2em 0;

  .price-legend {
    margin-bottom: 1.2em;
  }
}

div#password_resetter {
  position: absolute;
  z-index: 111;
  background: #fff;
  padding: 1em;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
  left: 0;
  right: 0;
  margin: 1.6em;
  max-width: 480px;
  width: 100%;
  border-radius: 13px;
  display: none;
  top: 20%;

  form#setNewPassword {
    button {
      margin-top: 1.2em;
      margin-bottom: 1.2em;
      display: inline-block;
      text-align: right;
    } 
  }
  &.active {
    display: block;
  }
}

#login_modal {
  position: fixed;
  top: 33%;
  /* bottom: 0; */
  left: 0;
  right: 0;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  height: 320px;
  padding: 2em;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  z-index: -1;
  display: none;

  p.alert-message {
    color: #B8272D;
    font-weight: bold;
  }

  &.active {
    z-index: 102;
  }

  #sendUserLogin {

    &.active {
      cursor: not-allowed;

      &:after {
        content: ' ';
        background-image: '/wp-content/themes/ct-2017/assets/images/ajax-loader.gif';
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        background-size: contain;
      }
    }
  }
}



@media only screen and (min-width: 863px) {
  #login_modal {
    left: calc(0px + 25%);
  }

  div#password_resetter {
    left: calc(0px + 25%);
    margin: auto;
  }
}

.seat-alert,
#performance_modal,
#tc_modal {
  position: fixed;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  left: calc(0px + 25%);
  right: 0;
  display: none;
  top: 20%;
  padding: 3em;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  z-index: 101;

  &.active {
    display: block;
  }

  p {
    color: #B8272D;
    font-weight: bold;
  }

  #closeLoginModal {
    margin-top: .6em;
  }
}

.calendar-promo-code,
.promo-codes {
  .message {
    color: #b8272d !important;
    font-size: 15px !important;
    width: 100%;
  }
}

.delivery-methods {
  input[type=radio] {
    margin-right: .4em;
  }

  label.radio-label.inline {
    display: inline;
    font-weight: 400;
    line-height: 1.8em;
  }
}

#tc_modal {
  p {
    color: 080808;
    font-weight: normal;
    padding: 1em;
    border: solid 1px #dfdfdf;
  }
}

@media(min-width: 768px) {
  .selectSeats__event {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: auto 1fr auto;
    gap: 0px 0px;
    grid-template-areas: "event_name venue_name"
      "seatsOnHold__body seatsOnHold__body"
      ". grand_total";
    margin-bottom: 1.6em;
  }
}

.seatsOnHold__body .seatsOnHold__seatMapList li {
  display: grid;
  grid-template-columns: 13% 9% 11% 40% 11% 13% 3%;
  gap: 0px;
  margin: .1em 0;
  background-color: #fdfdfd;
  padding: .4em 1.4em;
  font-size: 16px;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  span {
    text-align: center;

    &:nth-child(1), &:nth-child(4) {
      text-align: left;
      padding-left: .4em;
    }
  }

  &.headings {
    font-weight: bold;
    color: #b8272d;
    font-size: 14px;
    text-transform: uppercase;
    background-color: transparent;
    padding-bottom: .8em;
  }
}

.options {
  margin: 1em auto;
  text-align: center;
}

.seatsOnHold__body {
  grid-area: seatsOnHold__body;
  padding-bottom: 1em;
  border-bottom: solid 1px #ededed;
}

.grand_total {
  grid-area: grand_total;
  text-align: right;
  font-weight: 800;
  padding-top: 1em;
}

.event_name {
  grid-area: event_name;
}

.venue_name {
  grid-area: venue_name;
}


.selections {
  margin-top: 1em;
  background-color: #ededed;
  padding: 1.4em 1em .8em;

  .titleRow {
    font-weight: 600;
    border-bottom: solid 1px #a0a0a0;
    padding-bottom: .4em;
    margin-bottom: 1em;
  }
  .seat-picked {
    font-size: 16px;
  }
  .titleRow,
  .seat-picked {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;

    span.listsection {
      flex-basis: 20%;
    }
    span.listrow, span.listseat {
      flex-basis: 10%;
    }
    span.pricetype, span.listseatpricetype {
      flex-basis: 40%;
    }
    span.listamount {
      flex-basis: 20%;
    }
    a.seat-list__seat-remove {
      margin-left: auto;
    }
    
  }

}

a.seat-list__seat-remove,
a.on-order__seat-remove,
a.on-order__seat-final-remove {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 2px;
  border-bottom: none;
  padding: 2px;
  text-decoration: none;

  svg {
    width: 24px;
    height: auto;

    path {
      fill: #aaa;
    }

    &:hover {
      path {
        fill: #121212;
      }
    }
  }
}

.totals {
  display: flex;
  justify-content: space-between;
}

#av_bestAvailable {
  margin: 1.2em auto;
  padding: 1.2em;

  .bestAvailableOptions {
    margin: 1.2em;

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin: .3em 0;
      padding: .4em .8em;
      background-color: #dfdfdf;
    }
  }
}

#bestAvailableOptions {
  .dashicons.dashicons-tag {
    color: #b8272d;
    position: relative;
    top: 5px;
  }

}

button.disabled {
  cursor: not-allowed;
  color: #989898;
}

.price-legend {
  margin-top: 2.2em;
  padding: .8em 0;
  width: 100%;
  margin-bottom: 1.2em;
  box-sizing: border-box;

  .dashicons.dashicons-tag {
    color: #b8272d;
  }

  h4 {
    margin-bottom: 1.2em;
  }

  li {
    list-style-type: none;
    display: flex;
    margin-right: 2em;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    &:first-child {
      margin-right: 2em;
    }

    .legend-prices {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      margin-left: 0;
      width: 100%;
      line-height: 18px;

      .pricerow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: solid 1px #dfdfdf;
        padding: .4em 0;

        &:last-child {
          border-bottom: none;
        }
      }

    }

    span.seat-marker {
      display: inline-block;
      width: 1.66666em;
      height: 1.66666em;
      border-radius: 1.6666em;
      position: relative;
      top: 7px;
    }
  }

  .legend-items {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;

    align-items: center;
  }

}

#av_shoppingCart {
  margin: 1.2em auto;
  padding: 1.2em 3em;
  border-top: 2px solid #ccc;
  transition-duration: .3s;
  opacity: 0;

  .options {
    text-align: center;
  }
}

#av_seatmap {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;


  .overlay {
    display: none;
    transition-duration: .3s;
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 98;
  }

  .overlay.active {
    display: block;
  }
}

#av_bestAvail {
  min-height: 3.675em;
  position: relative;
}

#cursor_box {
  background-color: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  padding: 1em;
  border-radius: 50px;
  display: flex;
  align-items: baseline;
  z-index: 99;
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 480px;

  .message {
    color: #080808;
    margin-left: .8em;
  }

}

#page_modal {
  display: block;
  position: fixed;
  left: 25%;
  right: 0;
  max-width: 480px;
  margin: 0 auto;
  top: 30%;
  background-color: #fff;
  padding: 2em;
  border: solid 1px #dfdfdf;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  border-radius: 4px;
  opacity: 0;
  z-index: -1;

  &.active {
    z-index: 101;
  }
}

#page_overlay {
  position: relative;
  display: none;

  &.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 98;
    opacity: .4;
  }
}

#av_accountPreCheckout {

  h8 {
    font-size: 14px;
    font-weight: bold;
    display: block;
  }

  #billing_info {
    border-top: solid 1px #ddd;
  }

  #billing_info,
  #email_info,
  #shipping_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px #ddd;
    padding: 1em 0;

    #billingAddress {
      padding-top: 6px;
    }

    a.change-user-data {
      font-size: 14px;
    }

    .display {
      label {
        font-size: 16px;
      }
    }

    .info-edit {
      h5 {
        font-size: 14px;
        margin-bottom: .4em;
        margin-top: 6px;
        color: #444;
      }

      .address,
      .email-address {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        input[type=radio] {
          flex-basis: 4%;
          margin-right: 4%;
          padding-top: 5px;
        }

        label {
          font-weight: normal;
          font-size: calc(14px + 3 * ((100vw - 320px)/ 1120));
          line-height: 20px;
          margin: 8px 0;
        }
      }

      button {
        font-size: 14px;
        text-align: right;
        margin-top: 12px;
      }

      form a {
        font-size: 14px;
        margin-top: 8px;
        display: inline-block;
        line-height: 1.2em;
      }
    }

    .add-new-address,
    .add-new-email {
      margin-top: 1em;

      li {
        list-style-type: none;
        font-weight: normal;
        font-size: 16px;
        float: left;

        &.col-12 {
          width: 100%;
        }

        &.col-7 {
          width: 58.33333%;
          margin-right: 3px;
        }

        &.col-3 {
          width: 33.3333%;
        }

        label {
          font-size: 14px;
          font-weight: normal;
          margin-top: 8px;
        }
      }
    }
  }

}

.performances-on-order {
  flex-basis: 100%;
  flex-shrink: 0;

  .performances {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .performance {
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: .8em 1em;
      background-color: #efefef;
      margin: 1em 0 2em;
      border: solid 1px #dfdfdf;

      .performance__details {
        .performance__name {
          font-weight: 600;
          font-size: 18px;
          margin-top: 1.625em;
        }

        .performance__date {
          font-size: 14px;
        }
      }

      .performance__venue-details {
        margin-left: auto;

        .venue-name {
          font-size: 18px;
        }

        .venue-address {
          font-size: 14px;
        }
      }

      .final-seat-list {
        flex-basis: 98%;
        flex-shrink: 0;
        padding: .6em;
        background-color: #fff;

        .final-seat {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          text-align: left;
          padding: .4em;
          border-bottom: solid 1px #efefef;
          font-size: 16px;

          .seat-desc {
            flex-basis: 18%;
            margin-right: 2%;
          }

          .cost-amount {
            flex-basis: 10%;
            margin-right: 2%;
          }
          .seat-pricetype {
            flex-basis: 36%;
            margin-right: 4%;

            a {
              float: right;
              font-size: 14px;
            }
          }
          .fee-amount {
            flex-basis: 10%;
            margin-right: 2%;
          }

          .total-amount {
            flex-basis: 16%;
            margin-right: 2%;
          }
          .remove-seat {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.add-a-gift {
  background-color: #b8272d;
  padding: 1em;
  margin-top: 0em;
  position: relative;
  flex-basis: 41%;

  h5 {
    color: #fff;
  }

  input#addGiftDollar {
    padding-left: 32px;
    width: 6em;
  }

  i {
    position: absolute;
    left: 1.2em;
    top: 2.44em;
    font-size: 22px;
    font-style: normal;
  }

}

@media (min-width: 768px) {
  #av_accountPreCheckout {

    #billing_info,
    #email_info,
    #shipping_info {
      flex-direction: row;

      .display {
        flex-basis: 38.5%;
        margin-right: 3%;
      }

      .info-edit {
        flex-basis: 58.5%;
      }
    }
  }
}

#av_createNewAccount,
#av_checkout {
  margin: 1.2em auto;
  padding: 1.2em 3em;
  border-top: 2px solid #ccc;
  transition-duration: .3s;
  opacity: 0;
  display: none;

  h3 {
    margin-bottom: 1.2em;
  }

  .pswd-visible {
    font-size: 14px;
  }
}

.page-template-page-avcreate_account #av_createNewAccount {
  display: block;
  opacity: 1;
  max-width: 42rem;
}


#av_account {
  margin: 1.2em auto;
  padding: 2.4em 3em;
  border-top: 2px solid #ccc;
  transition-duration: .3s;
  opacity: 0;
  text-align: center;

  .button-panel {
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .4);
    padding: 3.6em 1.8em;
    margin: 1.2em auto;
    display: flex;
    justify-content: space-between;
    max-width: 380px;
  }
}

.paymentIcon {
  width: 42px;
  height: auto;
  margin-right: .8em;
  align-self: flex-start;
}

.paymentIcon_placeholder {
  display: inline-block;
  width: 46px;
  margin-right: .8em;
}

.onHoldTimer {
  position: fixed;
  width: 140px;
  height: auto;
  border: solid 1px #B8272D;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 14px;
  top: 80px;
  right: -300px;
  box-shadow: 1px 1px 3px rgb(0, 0, 0, .4);
  z-index: 99;
  transition-duration: .3s;

  .content {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
  }

  .timer {
    font-weight: 700;
    text-align: center;
  }

  &.active {
    right: 12px;
  }
}

.bundleContent {
  display: flex;
  width: 90%;

  .title h3 {
    margin: 1.4em 0;
  }

  .filters {
    opacity: 0;
    display: none;
    min-height: 24em;
  }

  #displayBundles {

    .filterError {
      margin-top: 4em;
      max-width: 66%;
      text-align: center;
    }

    #bundlesOnSale {
      list-style-type: none;
      padding-left: 0;
      width: 100%;

      li {
        margin: .16em 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: .2em .4em;
        background-color: #f1f1f1;
        flex-wrap: wrap;
        line-height: 42px;

        .bundleSeats {
          flex-basis: 100%;
          display: block;

          li {
            background-color: transparent !important;
            justify-content: flex-start !important;
            flex-wrap: wrap;

            label {
              flex-basis: 45%;
              font-size: 16px;
            }

            .bundleOptions {
              flex-basis: 60%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }

        &:nth-child(even) {
          background-color: #fafafa;
        }
      }
    }
  }

  &.flexSeats {
    flex-direction: column;

  }

  &.reserveSeats {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      flex-basis: 100%;
    }

    .filters {
      flex-basis: 32%;

      label {
        font-weight: 300;
        margin-top: .8em;
        max-width: 94%;

      }

      select {
        margin-bottom: .8em;
      }

      .field {
        border-bottom: solid 1px #dfdfdf;
      }
    }

    #displayBundles {
      flex-basis: 66%;
    }
  }
}

#page_login {
  margin-top: 3em;
}

#av_checkout {
  .order-summary {
    .btn-primary {
      background-color: #B8272D;
      color: #fff;
      margin-right: 1.2em;
    }
  }
}

@media (min-width: 768px) {
  .seat-legend {
    .legend-items {
      flex-direction: row;
    }
  }

  #av_seatmap {
    padding: 1.2em 3em;
  }

  .price-legend {

    li {
      margin-right: 3.9em;

      &:first-child {
        margin-right: 4.4em;
      }

      .legend-prices {
        font-size: 14px;
        margin-left: 38px;
      }
    }
  }
}