// Custom Components


// Photo Galleries
.photo-galleries {
  .grid-item {
    
  }
}

// Person Bio Pages

.person__name {
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (max-width: 29.9375em) {
  .person__img {
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 30em) {
  .person__img {
    float: left;
    margin-right: 30px;
    margin-bottom: @baselineheight;
    max-width: 200px;
  }
}

@media only screen and (min-width: 65em) {
  .person__bio {
    margin-left: 230px;
    max-width: @line-length;
  }
}


// Blog

.blog-teaser {
  border-top: 1px solid @bordercolor;
  padding: @baselineheight 0 0;
  margin-bottom: @baselineheight/2;
  .clearfix();
  .fluid-type(font-size, 320px, 1440px, 16px, 18px);
  .title {
    text-transform: none;
    .fluid-type(font-size, 320px, 1440px, 21px, 24px);
  }
  .event & {
    border: none;
    padding: 0;
  }
}
.blog-teaser__img {
  display: block;
  margin: 0 auto @baselineheight/2;
}
.blog-teaser__body {
  .font-size(15);
}
@media only screen and (min-width: 23em) {
  .blog-teaser__img {
    float: left;
    max-width: 100px;
    margin-right: 15px;
  }
}
@media only screen and (min-width: 30em) {
  .blog-teaser__img {
    float: left;
    max-width: 200px;
  }
  .blog-teaser__body {
    margin-left: 215px;
    &:first-child {
      // if there's no image
      margin-left: 0;
    }
  }
}