.skip {
  .visuallyhidden();
}

.live-video {
  
  a.site-logo {
    text-align: left;
    margin: 1em;
  }
  
  .video-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    > div {
      flex-basis: 100%;
      padding: 56% 0 0 0;
    }
    > p {
      flex-basis: 100%;
      
      #live-chat-app {
        
        form {
          margin: 0 2em;
        }
      }
    }
    p.no-break {
      flex-basis: 100%;
    }
  }
}

@media (min-width: 780px) {
  .live-video {
    .video-content {
      > div {
        flex-basis: 66%;
        padding: 0 0 0 0;
      }
      > p {
        flex-basis: 32%;
      }
    }
  }
}

.container {
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  clear: both;
  .clearfix;
}

.row {
  .clearfix;
}

.row--margined {
  .clearfix;
  margin-bottom: @baselineheight;
  &:last-of-type,
  > :last-child {
    margin-bottom: 0;
  }
}

.row--bordered {
  .clearfix;
  border-bottom: 1px solid @bordercolor;
  padding-bottom: @baselineheight;
  margin-bottom: @baselineheight;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
}

#site-main {
  min-height: 70vh;
}

.page__header {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 @baselineheight;
  padding: 1em 3%;
  background-color: @darkergrey;
  color: @white;
  position: relative;
  .title, h1 {
    margin: 0;
    color: @white;
  }
}