// Mixins

// Typography

.font-size(@font-size: 16){
  @rem: (@font-size / 16);
  font-size : @font-size * 1px;
  font-size : ~"@{rem}em";
}

.fluid-type(@property, @min-vw, @max-vw, @min-size, @max-size) {

	@{property}: @min-size;
	
	@media screen and (min-width: @min-vw) {
		@{property}: calc( @min-size ~" + " unit(@max-size - @min-size) ~" * ((100vw - " @min-vw ~") /" unit(@max-vw - @min-vw) ~")" );
	}

	@media screen and (min-width: @max-vw) {
		@{property}: @max-size;
	}

}

.margin-bottom(@margin: 1.4) {
  margin-bottom: @margin * 1em;
  margin-bottom: @margin * 1rem;
}

.text-overflow() {
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

// Retina background
.retina-image(@file, @type, @repeat, @width, @height, @posx, @posy) {
  background-image: url('@{file}.@{type}');
  background-repeat: @repeat;
  background-position: @posx @posy;
  @media screen and
    (-webkit-min-device-pixel-ratio: 1.5),
    (min-device-pixel-ratio: 1.5),
    (min-resolution: 144dpi),
    (min-resolution: 1.5dppx) {
      background-size: @width @height;
      background-image: url('@{file}@2x.@{type}');
    }
}

// Gradients

.horizontal(@startColor : @white, @endColor : @lightergrey, @bgColor: @endColor) {
  background-color: @bgColor;
  background-image  : linear-gradient(to right, @startColor, @endColor);
  background-repeat : repeat-x;
}

.vertical(@startColor : @white, @endColor:  @lightergrey, @bgColor: @endColor) {
  background-color: @bgColor;
  background-image  : linear-gradient(to bottom, @startColor, @endColor);
  background-repeat : repeat-x;
}

.directional(@startColor : @white, @endColor : @lightergrey, @bgColor: @endColor, @deg : 45deg) {
  background-color  : @bgColor;
  background-image  : linear-gradient(@deg, @startColor, @endColor);
  background-repeat : repeat-x;
}

// .bordered(COLOR, COLOR, COLOR, COLOR);

.bordered(@top-color: #eee, @right-color: #eee, @bottom-color: #eee, @left-color: #eee) {
  border-top : solid 1px @top-color;
  border-left : solid 1px @left-color;
  border-right : solid 1px @right-color;
  border-bottom : solid 1px @bottom-color;
  box-sizing: border-box;
}

// .rotate(VALUEdeg);

.rotate(@deg) {
  transform : rotate(@deg);
  outline: 1px solid transparent; //fixes jagged edges in FF
  backface-visibility: hidden; //fixes jagged edges in Chrome
  background-clip: padding-box; //fixes jagged edges in iOS
}

// Placeholder text color
.placeholder(@color: @placeholdercolor) {
  &:-moz-placeholder {
    color: @color;
  }
  &:-ms-input-placeholder {
    color: @color;
  }
  &::-webkit-input-placeholder {
    color: @color;
  }
}

// CSS Columns
.columns(@colwidth: 250px, @colcount: 0, @colgap: 50px, @columnRuleColor: #eee, @columnRuleStyle: solid, @columnRuleWidth: 1px) {
  column-width : @colwidth;
  column-count : @colcount;
  column-gap : @colgap;
  column-rule-color : @columnRuleColor;
  column-rule-style : @columnRuleStyle;
  column-rule-width : @columnRuleWidth;
}