// Home Page

.feature {
  margin-bottom: @baselineheight;
  clear: both;
  .clearfix();
}

.feature__img {
  width: 100%;
}

.feature__body {
  box-sizing: border-box;
  padding: @baselineheight/1.5 5%;
  .fluid-type(font-size, 320px, 1440px, 14px, 16px);
  p, ul, ol, blockquote {
    margin-bottom: @baselineheight/4;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

h2.feature__title {
  &:extend(.title);
  margin-bottom: @baselineheight/8;
  a {
    color: @linkcolor;
  }
}

.feature__cta {
  margin-top: @baselineheight/2;
  .fluid-type(font-size, 320px, 1440px, 14px, 16px);
  .btn {
    padding: 8px 12px;
    & + a {
      margin-left: .5em;
    }
  }
}

.feature--white {
  border-bottom: 6px solid @darkgrey;
  > .feature__body {
    background: #fff;
  }
}
.feature--black {
  > .feature__body {
    background: #000;
  }
}

.feature--black,
.feature--black .feature__title {
  color: #fff;
}

.feature--primary {
  &:extend(.feature all);
  > .feature__body {
    .fluid-type(font-size, 320px, 1440px, 16px, 20px);
  }
  .feature__title,
  h2.feature__title {
    .fluid-type(font-size, 320px, 1440px, 24px, 64px);
    line-height: 1;
    > a {
      color: inherit;
      border: none;
    }
  }
}

.feature--secondary {
  &:extend(.feature all);
  border: 1px solid #d9d9d9;
}

.feature--tertiary {
  &:extend(.feature all);
  padding: 0 5%;
  border-bottom: 1px solid @bordercolor;
  > a .feature__img {
    float: left;
    width: auto;
    max-width: 35%;
    margin: 0 15px @baselineheight/1.5 0;
  }
  > .feature__body {
    border: none;
    padding: 0 0 @baselineheight/2;
  }
  .feature__title {
    .fluid-type(font-size, 320px, 1440px, 16px, 24px);
  }
}


@media only screen and (min-width: 48em) {
  .feature--primary {
    position: relative;
  }
  .feature--primary > .feature__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    padding: 1em 5%;
    border: none;
  }
  .feature--white > .feature__body {
    background: #fff;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,1) 100%);
  }
  .feature--black > .feature__body {
    background: #000;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 20%,rgba(0,0,0,1) 100%);
  }
  
}

@media only screen and (min-width: @column-breakpoint-med) {
  .feature--secondary {
    margin-left: 5%;
    > .feature__body {
      padding: @baselineheight 1em;
    }
  }
  .feature--tertiary {
    padding: 0 1em 0.5em;
    margin-right: 5%;
    margin-bottom: @baselineheight;
  }
}

@media only screen and (min-width: @nav-break) {
  .home-container {
    padding-left: 2%;
  }

  .feature--primary > .feature__body {
    position: static;
    width: auto;
    padding: 1em;
    border: none;
  }
  .feature--white > .feature__body {
    background: #fff;
  }
  .feature--black > .feature__body {
    background: #000;
  }
  
}

@media only screen and (min-width: 65em) {
  .feature--primary > .feature__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    padding: 1em 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .feature--white > .feature__body {
    background: #fff;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,1) 100%);
  }
  .feature--black > .feature__body {
    background: #000;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 20%,rgba(0,0,0,1) 100%);
  }
  
  .feature--tertiary .feature__img + .feature__body {
    margin-left: ~'calc(35% + 15px)';
  }
}

@media only screen and (min-width: 90em) {
  .feature--primary > .feature__body {
    width: 50%;
  }
}