
.accountMenuWrapper {
  float: right;
  position: relative;
  clear: both;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  #account_menu {
    overflow: hidden;
    height: inherit;
    max-height: 0;
    transition-duration: 0.3s;
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    right: 0;
    min-width: 12em;
    top: 2.8em;
    z-index: 4;
    border: solid 1px transparent;
    display: block;
    background-color: #fff;
    
    li {
      
      
      a {
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        border-bottom: none;
        display: block;
        width: 100%;
        padding: .4em .6em;
        
        &:hover {
          border-bottom: none;
          background-color: #dfdfdf;
        }
      }
      
      &:hover {

      }
    }
  
    
    &.active {
      max-height: 1000px;
      border: solid 1px #dfdfdf;
    }
    &.closed {
      display: none;
    }
  }

}
#changeDefault {
  .options {
    text-align: left;
    .address, .contact {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
      text-align: left;

      input[type='radio'] {
        margin-right: 1em;
      }
      label {
        font-weight: normal;
      }
    }
  }
}
#login_modal {
  opacity: 0;
}

#communication_prefs_form {
  
  .field {
    background-color: transparent;

    display: flex;
    flex-direction: row;
    align-items: baseline;

    input[type='checkbox'] {
      margin-right: 1em;
    }

    label {
      flex-basis: 70%;
      font-weight: normal;
    }
  }
}
.addItem, #changeDefaultContact, #changeDefaultAddress {
  font-size: 14px;
}
#changeDefaultAddress, #changeDefaultContact {
  float: right;
}
.default-selection, .account-alert {
  opacity: 0;
  position: fixed;
  max-width: 400px;
  margin: 0 auto;
  padding: 2em;
  top: 20%;
  z-index: 1001;
  background-color: #fff;
  border: solid 1px #eee;
  box-shadow: 1px 1px 2px rgba(0,0,0,.3);
  left: 0;
  right: 0;
}
#accountModalOverlay{
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0,0,0,.3);
}
.accountContent {
  .accountContentInner {
    .panel {
      margin-bottom: 3.6em;

      li {
        list-style-type: none;
        padding: .4em .9em;
        background-color: #eee;
        margin-bottom: .2em;
        
        .item-options {
          float: right;
        }
      }
      .editForm {
        li {
          background-color: transparent;
        }
      }
    }
    
    h4 {
      margin-top: 1em;
    }
  }
}