// Utilities

// Contain floats

.clearfix {
  *zoom : 1;
  &:before,
  &:after {
    display: table;
    content : " "; }
  &:after {
    clear : both; }
}

// For image replacement

.hide-text() {
  text-indent : 200%;
  white-space : nowrap;
  overflow : hidden;
}

// Hide from visual and speaking browsers

.hidden() {
  display : none !important; 
  visibility : hidden;
}

// Hide but maintain layout

.invisible() { 
  visibility : hidden;
}

// Hidden but available to speaking browsers

.visuallyhidden { 
  overflow : hidden; 
  position : absolute;
  clip : rect(0 0 0 0); 
  height : 1px; 
  width : 1px;
  margin : -1px; 
  padding : 0; 
  border : 0;
}

// Make visuallyhidden focusable with a keyboard

.visuallyhidden.focusable:active, 
.visuallyhidden.focusable:focus { 
  position : static;
  clip : auto; 
  height : auto; 
  width : auto;
  margin : 0; 
  overflow: visible;
}

// .resize(VALUE) (none, both, horizontal, vertical, inherit)

.resize(@direction: both) {
  resize : @direction;
  overflow : auto;
}

// .userselect(VALUE) (all, element, none, text)

.user-select(@select) {
  -webkit-user-select : @select;
  -moz-user-select : @select;
  -o-user-select : @select;
  user-select : @select;
}

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Text Align
.center {
  text-align: center;
}