.event {
  position: relative;
}

.img--full {
  width: 100%;
}

.event__header {
  &:extend(.page__header all);
  padding-top: 2.4em;
}

.event__body {
  &:extend(.container all);
  background-color: @white;
}

.photo-galleries {
  p {
    .fluid-type(font-size, 320px, 1440px, 14px, 16px);
  }
}

@media only screen and (min-width: 30em) {
  .event__header__cta {
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 70em) {
  .event__header {
    .clearfix();
    padding-bottom: 1px;
    
  }
  .event__header__major,
  .event__header__minor {
    float: left;
  }
  .event__header__major {
    width: @goldenlarger;
  }
  .event__header__minor {
    width: @goldensmaller;
    text-align: right;
  }
}

// Videos
.video-item {
  margin-bottom: @baselineheight/2;
}

@media only screen and (min-width: 35em) {
  .videos-container {
    .clearfix();
    margin-left: -2%;
    margin-right: -2%;
  }
  .video-item {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
    float: left;
    &:nth-of-type(2n+1) {
      clear: left;
    }
  }
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	overflow: hidden;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Teasers

.teaser-collection {
  margin-bottom: @baselineheight;
  overflow: hidden;
  h2 + & {
    margin-top: -((@h2size/16)*(@baselineheight/4));
  }
}

.event-teaser {
  .clearfix();
  padding: @baselineheight/4 1em;
  margin-bottom: @baselineheight;
  border: 1px solid @bordercolor;
  .fluid-type(font-size, 320px, 1440px, 13px, 16px);

  .title {
    .font-size(@h3size);
    .fluid-type(font-size, 320px, 1440px, 22px, 30px);
    float: left;
    width: 62%;
    box-sizing: border-box;
    padding-right: 10px;
    margin-bottom: 0;
    > a {
      border-bottom: none;
      &:hover,
      &:focus {
        border-bottom: 1px solid;
      }
    }
  }
  
  p {
    margin-bottom: @baselineheight/2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.teaser-collection > .event-teaser {
  margin-bottom: 0;
}

.event-teaser + .event-teaser {
  border-top: none;
}

.event-teaser--compact {
  .title {
    .font-size(16);
    .fluid-type(font-size, 320px, 1440px, 16px, 20px);
  }
}

.event-teaser--full {
  padding: @baselineheight/1.5 1em;
  .title {
    .font-size(@h2size);
    .fluid-type(font-size, 320px, 1440px, 28px, 36px);
    margin-bottom: @baselineheight/8;
  }
}

.event-teaser--past {
  &:extend(.event-teaser--compact all);
  background-color: @lightestgrey;
  opacity: 0.75;
  .event-teaser__dates {
    .font-size(14);
  }
}

.event-teaser--current {
  &:extend(.event-teaser--full all);
  .teaser-collection & > {
    box-shadow: 0 -3px 10px rgba(0,0,0,.5);
  }
}

.event-teaser--future {
  padding-top: @baselineheight/2;
  padding-bottom: @baselineheight/2;
}

.event-teaser__dates {
  float: right;
  width: 38%;
  text-align: right;
  .font-size(16);
  .fluid-type(font-size, 320px, 1440px, 14px, 18px);
}

.event-teaser__top-billing {
  text-transform: uppercase;
  .font-size(14);
  .fluid-type(font-size, 320px, 1440px, 13px, 16px);
  line-height: 1.35;
}

.event-teaser__image {
  float: left;
  width: 40%;
  margin: 0 10px 10px 0;
}

.event-teaser__ctas {
  text-align: right;
  .btn {
    margin-left: 1em;
    padding: 8px 15px;
  }
}

@media only screen and (min-width: 40em) {
  .event-teaser__image {
    width: 33%;
  }
}

