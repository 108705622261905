#slider,
#carousel {
  margin-bottom: 0;
  box-shadow: none;
  background-color: @darkergrey;
  .flex-direction-nav a {
    border: none;
    box-sizing: border-box;
    padding-top: 8px;
    text-shadow: none;
    &:before {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

#slider {
  .slides {
    display: flex;
    align-items: center;
  }
  .slides img {
    max-height: 400px;
    max-height: 65vh;
    width: auto;
    margin: 0 auto;
  }
  .caption {
    margin: 0;
    padding: 1em;
    color: #ddd;
    &:empty {
      padding: 0;
    }
  }
}

#carousel {
  .slides > li {
    height: 140px;
  }
}

