/* Buttons =================================================== */

.btn {
  display : inline-block;
  padding : 10px 15px;
  margin-bottom : 0;
  font-family : @basefont;
  line-height : (@baselineheight*.75);
  color : @buttoncolor;
  text-decoration: none;
  text-align : center;
  vertical-align : middle;
  background-color : @buttonbackground;
  border : @buttonborderwidth @buttonborderstyle @buttonbordercolor;
  border-radius: @buttonborderradius;
//  box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
  cursor : pointer;
  transition: background-color .1s linear;
  
  &:visited,
  &:hover,
  &:focus {
    color : @buttoncolor;
    text-decoration : none;
  }
  
  &:hover,
  &:focus {
    background-color: @buttonbackgroundhover;
    color: @buttoncolorhover;
  }
  
  small {
    font-weight: normal;
    line-height: 1;
  }
}

.btn:first-child {
  *margin-left : 0;
}

// disabled
.btn--disabled {
  opacity: .5;
  cursor : default;
}

// button that looks like a link
.btn--link {
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: @linkcolor;
  padding: 0;
  line-height: normal;
  vertical-align: baseline;
  &:visited {
    color: @linkcolorvisited;
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: @linkcolorhover;
    text-decoration: underline;
  }
}

// primary
.btn--primary {
  background-color: @primarycolor;
  border-color : shade(@primarycolor, 25%);
  color: @primarycolorcontrast;
  text-transform: uppercase;
  font-family: @altfont;
  letter-spacing: 0.5px;
  &:visited,
  &:hover,
  &:focus {
    color : @primarycolorcontrast;
  }
  &:hover,
  &:focus {
    background-color: shade(@primarycolor, 20%);
  }
}

// sizes

.btn--extlarge {
  padding : 20px 35px;
  .font-size(@basefontsize*1.25);
}

.btn--large {
  padding : 15px 25px;
  .font-size(@basefontsize*1.1);
}

.btn--small {
  padding : 6px 10px;
  .font-size(13); 
}

.btn--mini {
  padding : 3px 5px;
  .font-size(11); 
}


// Block button

.btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}

// Vertically space out multiple block buttons
.btn--block + .btn--block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn--block {
    width: 100%;
  }
}
