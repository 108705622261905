/* Navigation */

.nav {
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    border-bottom: none;
  }
}

.main-nav {
  .font-size(15);
  .fluid-type(font-size, 320px, 1440px, 18px, 16px);
  padding-bottom: @baselineheight;
}

// Links
.main-nav li > a {
  float: none;
  padding: 0.35em 10%;
  background-color: @navLinkBackground;
  color: @navLinkColor;
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    color: @navLinkColorHover;
    text-decoration: none;
    text-shadow: none;
    background-color: @navLinkBackgroundHover;
  }
}

// Current nav items
.main-nav .current > a,
.main-nav .current > a:hover,
.main-nav .current > a:focus {
  color: @navLinkColorCurrent;
  text-decoration: none;
  background-color: @navLinkBackgroundCurrent;
  font-weight: bold;
}

// Subnav
.main-nav li ul {
  font-size: 0.9375em;
  li {
    a {
      padding: 0.35em 10% 0.35em 15%;
    }
  }
}

// Third-Level
.main-nav li ul li ul {
  li {
    a {
      padding-left: 25%;
    }
  }
}

@media only screen and (min-width: @nav-break) {

}

// Navbar search
.nav__search {
  position: relative;
  margin-bottom: 0;
  input[type='text'],
  input[type='search'] {
    float: left;
    margin-bottom: 0;
    padding: 4px 27px 4px 14px;
    border-radius: 30px;
  }
  button[type='submit'] {
    float: left;
    width: 25px;
    height: 25px;
    border: none;
    background: none;
    margin-left: -27px;
    margin-top: 1px;
  }
}

