// single text input with a submit button next to it
.input-and-submit {
  display: flex;
  > input[type='text'],
  > input[type='search'],
  > input[type='url'],
  > input[type='number'],
  > input[type='tel'] {
    flex-grow: 1;
    margin-bottom: 0;
  }
  > button,
  > input[type="submit"] {
    margin-left: 2px;
    padding: 8px 12px;
    vertical-align: top;
  }
}

.form__item {
  .margin-bottom(@basebottommargin);
}
label.required::after {
  content: "*";
  color: #B8272D;
  margin-left: .1em;
}
.input-mini { 
width : 60px; }

.input-small { 
width : 90px; }

.radio,
.checkbox {
font-weight : normal;
.font-size(15);
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
float : left;
margin-top: 6px;
margin-right: 5px;
}

.radio.inline,
.checkbox.inline {
display : inline-block;
padding-top : 5px;
margin-bottom : 0;
vertical-align : middle; }

.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
margin-left : 10px; }

// help text

.help-block,
.help-inline {
color : @grey; 
.font-size(12);
}

.help-block {
display : block; 
margin-bottom : @baselineheight / 2; }

.help-inline {
display : inline-block;
vertical-align : middle;
padding-left : 5px; }

// .form-inline

.form-inline input,
.form-inline textarea,
.form-inline select {
display : inline-block;
margin-bottom : 0; }

.form-inline label {
display : inline-block; }

.form-inline .radio,
.form-inline .checkbox,
.form-inline .radio {
padding-left : 0;
margin-bottom : 0;
vertical-align : middle; }

.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
float : left;
margin-left : 0;
margin-right : 3px; }

// .form-search

.form-search input,
.form-search textarea,
.form-search select {
display : inline-block;
margin-bottom : 0; }

.form-search .search-query {
padding-left : 14px;
padding-right : 14px;
margin-bottom : 0; 
border-radius: 14px; }

.form-search label {
display : inline-block; }

.form-search .radio,
.form-search .checkbox,
.form-inline .radio {
padding-left : 0;
margin-bottom : 0;
vertical-align : middle; }

.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"] {
float : left;
margin-left : 0;
margin-right : 3px; }

// gform overrides
#site-main {
  .gform_wrapper input[type=email],
  .gform_wrapper input[type=number],
  .gform_wrapper input[type=password],
  .gform_wrapper input[type=tel],
  .gform_wrapper input[type=text],
  .gform_wrapper input[type=url] {
    padding: 6px 8px;
    text-indent: 0;
  }
/*
  .gform_wrapper .top_label input.medium,
  .gform_wrapper .top_label select.medium,
  .gform_wrapper .top_label input.small,
  .gform_wrapper .top_label select.small {
    width: 100%;
  }
*/
  .gform_fields .hidden {
    display: none;
  }

  .gform_wrapper .top_label .gfield_description {
    padding: 0;
  }
  .gform_wrapper li.field_description_above .gfield_description {
    margin-bottom: @baselineheight/4;
  }

  .gform_wrapper .top_label .gfield_label {
    margin: 0;
    line-height: inherit;
  }
  .gform_wrapper .gsection .gfield_label,
  .gform_wrapper h2.gsection_title,
  .gform_wrapper h3.gform_title {
    font-weight: 400;
    margin: @baselineheight 0 @baselineheight/2;
  }
  .gform_wrapper h2.gsection_title {
    .font-size(28);
  }
  .gform_wrapper h3.gform_title {
    .font-size(22);
  }
  .gform_wrapper .ginput_complex.ginput_container {
    box-sizing: border-box;
    padding: 0 1em 1em;
    border: 1px solid @bordercolor;
    margin-bottom: @baselineheight;
  }
  .gform_wrapper ul.gfield_checkbox,
  .gform_wrapper ul.gfield_radio {
    margin-bottom: @baselineheight;
  }
  .gform_wrapper .gfield_checkbox li,
  .gform_wrapper .gfield_radio li {
    margin: 0;
  }
  .gform_wrapper .gfield_checkbox li label,
  .gform_wrapper .gfield_radio li label {
    font-weight: 300;
    .font-size(15);
  }
  .gform_wrapper .gfield_html.gfield_no_follows_desc {
    margin-top: @baselineheight;
  }
  
}


// 2019 - New Donate form customizations:


.gform_wrapper .gf_progressbar {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
}

#bestAvailableOptions {
  margin-top: 1.6em;

  .price-option {
    display: flex;
    padding: .4em;
    margin-bottom: .6em;
    border-bottom: solid 1px #ddd;

      label {
        flex-basis: 33%;
      }

      .price-option__seats {
        // margin-left: auto;
        margin-right: 1em;
      }

      &:last-child {
        border-bottom: none;
      }
      
  }
}
#newAccountForm {
  max-width: 42em;
  margin: 0 auto;

  #password, #comp_password {
    max-width: 75%;
  }
}

label.error, p.error {
  color: #B8272D;
  font-size: 14px;
  font-weight: bold;
}

input.error {
  border-color:#B8272D

}

#site-main .gform_wrapper .ginput_complex.ginput_container {
  border: none;
}

.line-balance_due {
  border: solid #aaa;
  border-width: 1px 0;
  padding: 1em 0 0.4em;
}
#cancelSecondPayment {
  margin-left: 14vw;
}

#av_guestPreCheckout, #checkoutPaymentForm, #secondaryCheckoutPayment {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

#creditCardForm, #secCreditCardForm, #giftCertificateForm, #secGiftCertificateForm, #accountBalanceForm {
  flex-basis: 100%;
}

#accountBalanceForm {

  #availableBalance {
    font-weight: 700;
  }
}

#checkoutPaymentForm {
  margin-top: 2.4em;
}

.checkout.order-summary {
  flex-basis: 48%;
  margin-left: auto;

  .orderSummary {

    li {
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #dfdfdf;
      align-items: baseline;

      h6 {
        font-weight: 400;

        a.remove-gift {
          font-size: 16px;
          margin-left: .6em;
        }
        a.remove-payment {
          font-weight: 200;
        }
      }
      span {
        margin-left: auto;
      }
      &:last-child {
        margin-top: .6em;
        border-bottom: none;
        font-weight: 800;

        h6 {
          font-weight: 800;
        }
      }
    }
  }
}

#shipping_info {
  h5 {
    margin-right: 1.2em;
  }
}

.payment_type_radio, .sec_payment_type_radio {
  
  padding-top: 1.2em;
  display: grid; 
  grid-template-columns: 56px 1.2em auto;
  align-items: baseline;
  gap: 0px 0px; 
  width: 100%; 
  height: 100%;
}

.full-col {
  flex-basis: 100%;
  flex-shrink: 0;
}

.fieldset {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: no-wrap;
  list-style-type: none;
  
  &.half-col {
    width: 100%;
  }

  .field {
    list-style-type: none  !important;
    padding: 2px 4px;
    box-sizing: border-box;
    max-width: 540px;
    
    &.inline {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
      justify-content: flex-start;
    }
    
    input[type="text"], input[type="password"] {
      width: 100%;
      // max-width: 420px;
    }
    
    input[type="text"], input[type="select"] {
      
      &:disabled {
        cursor: not-allowed;
        border-color: #ddd;
      }
    }
    
    input[type="checkbox"] {
      text-align: left;
      margin-right: 18px;
    }
    label {
      font-weight: 500;
      font-size: 16px;
      &.inline {
        display: inline-block;
      }
      &.disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
    .field_description, .field_description_list {
      font-size: 15px;
      color: #999;
    }
   .field_description_list {
     list-style-type: disc;
   }
  }
  
  .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    flex-basis: 100%;
    
    &.clear {
      margin-right: 100%;
    }
  }
}

@media (min-width: 580px) {
  .fieldset {
    flex-direction: column;
    
    .col-2 {
      margin: 0;
      flex-basis: 33.3333%;
    }
    .col-3 {
      margin: 0;
      flex-basis: 50%;
    }
    .col-4 {
      margin: 0;
      flex-basis: 50%;
    }
    .col-5 {
      margin: 0;
      flex-basis: 50%;
    }
    .col-6 {
      margin: 0;
      flex-basis: 100%;
    }
    .col-7 {
      margin: 0;
      flex-basis: 100%;
    }
    .col-8 {
      margin: 0;
      flex-basis: 100%;
    }
    .col-9 {
      margin: 0;
      flex-basis: 100%;
    }
    .col-10 {
      margin: 0;
      flex-basis: 100%
    }
    .col-12 {
      margin: 0;
      flex-basis: 100%;
    }
    &.half {
      width: 100%;
    }
    
  }
}

@media (min-width: 768px) {
  .fieldset {
    flex-direction: row;

    &.half-col {
      width: 48.5%;
    }

    .col-2 {
      margin: 0;
      flex-basis: 16.6666%;
    }
    .col-3 {
      margin: 0;
      flex-basis: 25%;
    }
    .col-4 {
      margin: 0;
      flex-basis: 33.333%;
    }
    .col-5 {
      margin: 0;
      flex-basis: 41.666%;
    }
    .col-6 {
      margin: 0;
      flex-basis: 50%;
    }
    .col-7 {
      margin: 0;
      flex-basis: 58.333%;
    }
    .col-8 {
      margin: 0;
      flex-basis: 66.666%;
    }
    .col-9 {
      margin: 0;
      flex-basis: 75%;
    }
    .col-10 {
      margin: 0;
      flex-basis: 83.333%
    }
    .col-12 {
      margin: 0;
      flex-basis: 100%;
    }
    &.half {
      width: 48.8%;
    
      .col-2 {
        margin: 0;
        flex-basis: 33.333%;
      }
      .col-3 {
        margin: 0;
        flex-basis: 50%;
      }
      .col-4 {
        margin: 0;
        flex-basis: 75%;
      }
      .col-5 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-6 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-7 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-8 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-9 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-10 {
        margin: 0;
        flex-basis: 100%
      }
      .col-12 {
        margin: 0;
        flex-basis: 100%;
      }
    }
    input[type="text"], input[type="password"] {
//       max-width: 600px;

    }
  }
}

@media (min-width: 768px) {
  .fieldset {
    flex-direction: row;
    flex-wrap: wrap;

    .col-2 {
      margin: 0;
      flex-basis: 16.6666%;
    }
    .col-3 {
      margin: 0;
      flex-basis: 25%;
    }
    .col-4 {
      margin: 0;
      flex-basis: 33.333%;
    }
    .col-5 {
      margin: 0;
      flex-basis: 41.666%;
    }
    .col-6 {
      margin: 0;
      flex-basis: 50%;
    }
    .col-7 {
      margin: 0;
      flex-basis: 58.333%;
    }
    .col-8 {
      margin: 0;
      flex-basis: 66.666%;
    }
    .col-9 {
      margin: 0;
      flex-basis: 75%;
    }
    .col-10 {
      margin: 0;
      flex-basis: 83.333%
    }
    .col-12 {
      margin: 0;
      flex-basis: 100%;
    }
    &.half {
      width: 48.8%;
    
      .col-2 {
        margin: 0;
        flex-basis: 33.333%;
      }
      .col-3 {
        margin: 0;
        flex-basis: 50%;
      }
      .col-4 {
        margin: 0;
        flex-basis: 75%;
      }
      .col-5 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-6 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-7 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-8 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-9 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-10 {
        margin: 0;
        flex-basis: 100%
      }
      .col-12 {
        margin: 0;
        flex-basis: 100%;
      }
    }
    input[type="text"], input[type="password"] {
//       max-width: 600px;
    }
  }
  li.payment_type_radio, li.sec_payment_type_radio {
    position: relative;

    #payment_option-error {
      position: absolute;
      right: -3em;
      top: -2em;
    }
  }
  
  
  #creditCardForm, #giftCertificateForm, #secCreditCardForm, #secGiftCertificateForm, #accountBalanceForm {
    flex-basis: 48.5%;
  }
}


@media (min-width: 992px) {

}


@media (min-width: 1054px) {
  .fieldset {
    flex-direction: row;

    &.half {
    
      .col-2 {
        margin: 0;
        flex-basis: 25%;
      }
      .col-3 {
        margin: 0;
        flex-basis: 33.333%;
      }
      .col-4 {
        margin: 0;
        flex-basis: 41.666%;
      }
      .col-5 {
        margin: 0;
        flex-basis: 50%;
      }
      .col-6 {
        margin: 0;
        flex-basis: 58.333%;
      }
      .col-7 {
        margin: 0;
        flex-basis: 66.666%;
      }
      .col-8 {
        margin: 0;
        flex-basis: 75%;
      }
      .col-9 {
        margin: 0;
        flex-basis: 83.333%
      }
      .col-10 {
        margin: 0;
        flex-basis: 100%;
      }
      .col-12 {
        margin: 0;
        flex-basis: 100%;
      }
    }
    input[type="password"] {
//       max-width: 600px;

    }
  }
}


