.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}
.visuallyhidden {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  overflow: visible;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.center {
  text-align: center;
}
html {
  height: 100%;
  background: #ffffff;
  font-size: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.125em;
  font-size: 16px;
  line-height: 1.65em;
  color: #444;
}
@media screen and (min-width: 320px) {
  body {
    font-size: calc(16px  +  3  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  body {
    font-size: 19px;
  }
}
a {
  text-decoration: none;
  color: #B8272D;
  background-color: transparent;
  border-bottom: 1px dotted;
}
a:visited {
  color: #B8272D;
}
a:hover {
  color: #811b1f;
  border-bottom-style: solid;
}
a:focus {
  outline: thin dotted;
  color: #811b1f;
}
a:hover,
a:active {
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-family: inherit;
  font-weight: bold;
  color: #000000;
  margin: 0 0 0.825em;
}
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.4125em;
}
h1 {
  font-size: 45px;
  font-size: 2.8125em;
  font-size: 30px;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin-bottom: 0.4125em;
  background: transparent;
  color: #000000;
  padding: 0;
  margin-top: 0.9125em;
}
@media screen and (min-width: 320px) {
  h1 {
    font-size: calc(30px  +  26  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  h1 {
    font-size: 56px;
  }
}
h2 {
  font-size: 26px;
  font-size: 1.625em;
  font-size: 24px;
  text-transform: uppercase;
  background-color: #404040;
  color: #ffffff;
  padding: 0.25em 0.5em 0.2em;
  letter-spacing: 0.5px;
}
@media screen and (min-width: 320px) {
  h2 {
    font-size: calc(24px  +  8  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  h2 {
    font-size: 32px;
  }
}
h2 a:link,
h2 a:visited {
  color: #db2f36;
}
h2 a:hover,
h2 a:focus {
  color: #B8272D;
}
h3 {
  font-size: 21px;
  font-size: 1.3125em;
}
h4 {
  font-size: 18px;
  font-size: 1.125em;
}
h5 {
  font-size: 16px;
  font-size: 1em;
}
h6 {
  font-size: 14px;
  font-size: 0.875em;
}
p,
ol,
ul,
dl,
menu,
address,
pre,
blockquote {
  margin: 0;
  margin-bottom: 1.65em;
  margin-bottom: 1.65rem;
  line-height: 1.65em;
}
small {
  font-size: 80%;
}
ul,
ol,
menu {
  padding-left: 1.2em;
}
li ul,
li ol {
  margin: 0;
}
nav ul,
nav ol,
form ul,
form ol {
  list-style: none;
  list-style-image: none;
  padding: 0;
}
dl,
dd {
  margin-bottom: 1.65em;
}
dt {
  font-weight: bold;
}
address {
  font-style: normal;
}
q {
  quotes: none;
}
blockquote {
  color: #7c7c7c;
  border-left: 3px solid #d9d9d9;
  padding-left: 1.2em;
  font-style: italic;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
cite {
  font-style: normal;
}
b,
strong {
  font-weight: bold;
}
pre,
code,
kbd,
samp {
  font-family: Monaco, Consolas, Courier New, monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
mark {
  background-color: #e0ebf0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  vertical-align: baseline;
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
dfn {
  font-style: italic;
}
hr {
  box-sizing: content-box;
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 0;
  border: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #ffffff;
}
img {
  max-width: 100%;
  width: auto;
  height: auto;
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
svg {
  max-height: 100%;
  max-width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
iframe {
  max-width: 100%;
}
figure {
  margin: 0 0 1.65em;
}
figure > img {
  margin-bottom: 0.4125em;
}
figure > figcaption {
  display: block;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875em;
  line-height: 1.65em;
  color: #999999;
}
/* Tables =================================================== */
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.65em;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
}
th,
td {
  padding: 8px;
  border-top: 1px solid #d9d9d9;
  text-align: left;
  line-height: inherit;
  vertical-align: top;
}
th {
  font-weight: bold;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  text-transform: uppercase;
  background-color: #262626;
  color: #ffffff;
}
thead th {
  vertical-align: bottom;
}
colgroup + thead tr:first-child th,
colgroup + thead tr:first-child td,
thead:first-child tr:first-child th,
thead:first-child tr:first-child td {
  border-top: 0;
}
tbody + tbody {
  border-top: 2px solid #d9d9d9;
}
/* Forms =================================================== */
form {
  margin: 0;
}
form small {
  color: #b3b3b3;
}
form p {
  margin-bottom: 0.825em;
}
fieldset {
  margin: 0;
  padding: 0;
  border: none;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  margin-bottom: 0.825em;
  margin-bottom: 0.825rem;
  padding: 0;
  border: 0;
  color: #444;
  font-weight: bold;
  white-space: normal;
}
label {
  display: block;
  max-width: 100%;
  margin-bottom: 0.4125em;
  margin-bottom: 0.4125rem;
  font-weight: bold;
}
label small {
  font-weight: normal;
}
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  overflow: visible;
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 0;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  line-height: 1.2375em;
  color: #444;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  background-color: #d9d9d9;
  border: 0 none #c3c3c3;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}
button:visited,
input[type="button"]:visited,
input[type="reset"]:visited,
input[type="submit"]:visited,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  color: #444;
  text-decoration: none;
}
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  background-color: #c3c3c3;
  color: #444;
}
button small,
input[type="button"] small,
input[type="reset"] small,
input[type="submit"] small {
  font-weight: normal;
  line-height: 1;
}
input[type="submit"] {
  background-color: #B8272D;
  border-color: #8a1d22;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  letter-spacing: 0.5px;
}
input[type="submit"]:visited,
input[type="submit"]:hover,
input[type="submit"]:focus {
  color: #ffffff;
}
input[type="submit"]:hover,
input[type="submit"]:focus {
  background-color: #931f24;
}
input,
button,
select,
textarea {
  font-size: 16px;
  font-size: 1em;
  vertical-align: baseline;
  *vertical-align: middle;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
}
input,
textarea,
select {
  display: inline-block;
  margin-bottom: 0.4125em;
  color: #5e5e5e;
}
input,
textarea {
  padding: 8px 10px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #b4b4b4;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #b4b4b4;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b4b4b4;
}
input:hover,
textarea:hover {
  border-color: #adadad;
}
/* Correcting text-transform inheritance on these elements */
button,
select {
  text-transform: none;
}
/* FF3+ fixes */
button,
input {
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/* make sure placeholder goes away on focus */
[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}
[placeholder]:focus::-moz-placeholder {
  color: transparent;
}
[placeholder]:focus::-ms-input-placeholder {
  color: transparent;
}
input[type="image"],
input[type="checkbox"],
input[type="radio"] {
  width: auto;
  height: auto;
  padding: 0;
  line-height: normal;
  cursor: pointer;
  border-radius: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="image"] {
  border: 0;
  box-shadow: none;
}
input[type="file"] {
  width: auto;
  padding: initial;
  line-height: initial;
  border: initial;
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
input[type="button"],
input[type="reset"],
input[type="submit"] {
  width: auto;
  height: auto;
  cursor: pointer;
  *overflow: visible;
}
input[type="number"] {
  width: auto;
}
input[type="range"] {
  display: block;
  width: 100%;
}
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
select {
  font-size: 15px;
  font-size: 0.9375em;
}
select[multiple],
select[size] {
  height: auto;
}
textarea {
  resize: vertical;
  width: 100%;
  height: auto;
  overflow: auto;
  vertical-align: top;
}
input[type="hidden"] {
  display: none;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-color: #989898;
  color: #444;
  outline: 0;
  outline: thin dotted \9;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
  box-shadow: none;
}
#account_tab {
  position: absolute;
  right: 0;
  top: 50px;
  padding: 0.4em 1em;
  background-color: #b8272d;
  color: #fff;
  font-size: 14px;
  border-radius: 0 0 0 2px;
  z-index: 999;
}
#account_tab p {
  margin: 0 !important;
}
#account_tab p a {
  color: #fff;
  border-color: #fff;
  cursor: pointer;
}
#account_tab p a.account-tab__create-account {
  margin-right: 0.6em;
}
#account_tab p a.account-tab__login {
  margin-left: 0.6em;
}
#account_tab p a.account-tab__logout.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: #dfdfdf;
}
@media only screen and (min-width: 863px) {
  #account_tab {
    top: 0;
  }
}
body.admin-bar #account_tab {
  top: 30px !important;
}
/* Type Classes */
.title,
.alert__heading,
h2.feature__title {
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin-bottom: 0.4125em;
  background: transparent;
  color: #000000;
  padding: 0;
}
.subtitle {
  font-family: inherit;
  font-size: 18px;
  font-size: 1.125em;
  line-height: 1.35;
}
.byline {
  color: #7c7c7c;
}
.subheading {
  text-transform: uppercase;
  background-color: #404040;
  color: #ffffff;
  padding: 0.25em 0.5em 0.2em;
  letter-spacing: 0.5px;
}
.subheading a:link,
.subheading a:visited {
  color: #db2f36;
}
.subheading a:hover,
.subheading a:focus {
  color: #B8272D;
}
.promo {
  text-transform: uppercase;
  margin-bottom: 0;
}
.top-billing {
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1em;
}
.meta {
  color: #7c7c7c;
  font-size: 14px;
  font-size: 0.875em;
  text-transform: uppercase;
  margin-bottom: 0.825em;
}
.lesser {
  color: #7c7c7c;
  font-size: 14px;
  font-size: 0.875em;
  font-weight: normal;
}
.caption {
  color: #7c7c7c;
  font-size: 12px;
  font-size: 0.75em;
  line-height: 1.2;
}
.lead {
  font-size: 18px;
  font-size: 1.125em;
  line-height: 1.5;
}
.person__role {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875em;
  line-height: 1.2;
}
.list--plain,
.social-links {
  padding-left: 0;
}
.list--plain li {
  list-style: none;
}
.list--prominent li {
  font-size: 16px;
  font-size: 1em;
  line-height: 1.65em;
  margin-bottom: 1.65em;
}
.list--prominent li:last-child {
  margin-bottom: 0;
}
.list--prominent li ul {
  margin-top: 0.825em;
}
.list--prominent li ul li {
  font-size: 14px;
  font-size: 0.875em;
  margin-bottom: 0.825em;
}
.list--comfortable li {
  margin-bottom: 0.825em;
}
.list--comfortable li:last-child {
  margin-bottom: 0;
}
.list--cozy li {
  margin-bottom: 0.4125em;
}
.list--cozy li:last-child {
  margin-bottom: 0;
}
.list--bordered li {
  padding-bottom: 0.825em;
  border-bottom: 1px solid #ededed;
}
.list--bordered li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.list--bordered li ul li {
  padding-bottom: 0;
  border-bottom: none;
}
.list--columns,
.list--columns--narrow {
  column-width: 18em;
  column-count: 4;
  column-gap: 2em;
  column-rule-color: transparent;
  column-rule-style: none;
  column-rule-width: 0;
}
.list--columns > li,
.list--columns--narrow > li {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.list--columns--narrow {
  column-width: 10em;
}
.list--inline > li {
  display: inline;
  margin-right: 1em;
}
.dl--horizontal dt {
  float: left;
  clear: left;
  width: 20.25%;
  text-align: right;
}
.dl--horizontal dd {
  margin-left: 22.78%;
}
.blockquote {
  font-style: normal;
  padding: 0 1em;
}
.blockquote p,
.blockquote .quote {
  margin: 0 0 0.4125em;
}
.blockquote .quote:before {
  content: '\201C';
}
.blockquote .quote:after {
  content: '\201D';
}
.blockquote footer:before,
.blockquote .attr:before {
  content: '\2014\00A0';
}
.align-left {
  display: inline;
  float: left;
  margin-right: 1.65em;
  margin-bottom: 0.825em;
}
.align-right {
  display: inline;
  float: right;
  margin-left: 1.65em;
  margin-bottom: 0.825em;
}
.align-center {
  clear: both;
  display: block;
  margin: 0 auto 0.825em;
}
.table-container {
  max-width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
  margin-bottom: 1.65em;
}
.event-table {
  margin-bottom: 0;
}
.event-table th,
.event-table td {
  padding: 0.4125em 1em;
  line-height: 1.2;
  vertical-align: middle;
}
.event-table th {
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  text-transform: uppercase;
  background-color: #262626;
  color: #ffffff;
}
.event-table td {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .event-table td {
    font-size: calc(14px  +  2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-table td {
    font-size: 16px;
  }
}
td.event-table__title {
  width: 40%;
  font-size: 16px;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (min-width: 320px) {
  td.event-table__title {
    font-size: calc(16px  +  4  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  td.event-table__title {
    font-size: 20px;
  }
}
td.event-table__title a {
  border: none;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #d9d9d9;
  border-left: 0;
  border-collapse: separate;
  *border-collapse: collapsed;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #d9d9d9;
}
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #ededed;
}
.input-and-submit {
  display: flex;
}
.input-and-submit > input[type='text'],
.input-and-submit > input[type='search'],
.input-and-submit > input[type='url'],
.input-and-submit > input[type='number'],
.input-and-submit > input[type='tel'] {
  flex-grow: 1;
  margin-bottom: 0;
}
.input-and-submit > button,
.input-and-submit > input[type="submit"] {
  margin-left: 2px;
  padding: 8px 12px;
  vertical-align: top;
}
.form__item {
  margin-bottom: 1.65em;
  margin-bottom: 1.65rem;
}
label.required::after {
  content: "*";
  color: #B8272D;
  margin-left: 0.1em;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.radio,
.checkbox {
  font-weight: normal;
  font-size: 15px;
  font-size: 0.9375em;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-top: 6px;
  margin-right: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.help-block,
.help-inline {
  color: #999999;
  font-size: 12px;
  font-size: 0.75em;
}
.help-block {
  display: block;
  margin-bottom: 0.825em;
}
.help-inline {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}
.form-inline input,
.form-inline textarea,
.form-inline select {
  display: inline-block;
  margin-bottom: 0;
}
.form-inline label {
  display: inline-block;
}
.form-inline .radio,
.form-inline .checkbox,
.form-inline .radio {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-left: 0;
  margin-right: 3px;
}
.form-search input,
.form-search textarea,
.form-search select {
  display: inline-block;
  margin-bottom: 0;
}
.form-search .search-query {
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 0;
  border-radius: 14px;
}
.form-search label {
  display: inline-block;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"] {
  float: left;
  margin-left: 0;
  margin-right: 3px;
}
#site-main {
  /*
  .gform_wrapper .top_label input.medium,
  .gform_wrapper .top_label select.medium,
  .gform_wrapper .top_label input.small,
  .gform_wrapper .top_label select.small {
    width: 100%;
  }
*/
}
#site-main .gform_wrapper input[type=email],
#site-main .gform_wrapper input[type=number],
#site-main .gform_wrapper input[type=password],
#site-main .gform_wrapper input[type=tel],
#site-main .gform_wrapper input[type=text],
#site-main .gform_wrapper input[type=url] {
  padding: 6px 8px;
  text-indent: 0;
}
#site-main .gform_fields .hidden {
  display: none;
}
#site-main .gform_wrapper .top_label .gfield_description {
  padding: 0;
}
#site-main .gform_wrapper li.field_description_above .gfield_description {
  margin-bottom: 0.4125em;
}
#site-main .gform_wrapper .top_label .gfield_label {
  margin: 0;
  line-height: inherit;
}
#site-main .gform_wrapper .gsection .gfield_label,
#site-main .gform_wrapper h2.gsection_title,
#site-main .gform_wrapper h3.gform_title {
  font-weight: 400;
  margin: 1.65em 0 0.825em;
}
#site-main .gform_wrapper h2.gsection_title {
  font-size: 28px;
  font-size: 1.75em;
}
#site-main .gform_wrapper h3.gform_title {
  font-size: 22px;
  font-size: 1.375em;
}
#site-main .gform_wrapper .ginput_complex.ginput_container {
  box-sizing: border-box;
  padding: 0 1em 1em;
  border: 1px solid #d9d9d9;
  margin-bottom: 1.65em;
}
#site-main .gform_wrapper ul.gfield_checkbox,
#site-main .gform_wrapper ul.gfield_radio {
  margin-bottom: 1.65em;
}
#site-main .gform_wrapper .gfield_checkbox li,
#site-main .gform_wrapper .gfield_radio li {
  margin: 0;
}
#site-main .gform_wrapper .gfield_checkbox li label,
#site-main .gform_wrapper .gfield_radio li label {
  font-weight: 300;
  font-size: 15px;
  font-size: 0.9375em;
}
#site-main .gform_wrapper .gfield_html.gfield_no_follows_desc {
  margin-top: 1.65em;
}
.gform_wrapper .gf_progressbar {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: #ffffff !important;
  border-radius: 0 !important;
}
#bestAvailableOptions {
  margin-top: 1.6em;
}
#bestAvailableOptions .price-option {
  display: flex;
  padding: 0.4em;
  margin-bottom: 0.6em;
  border-bottom: solid 1px #ddd;
}
#bestAvailableOptions .price-option label {
  flex-basis: 33%;
}
#bestAvailableOptions .price-option .price-option__seats {
  margin-right: 1em;
}
#bestAvailableOptions .price-option:last-child {
  border-bottom: none;
}
#newAccountForm {
  max-width: 42em;
  margin: 0 auto;
}
#newAccountForm #password,
#newAccountForm #comp_password {
  max-width: 75%;
}
label.error,
p.error {
  color: #B8272D;
  font-size: 14px;
  font-weight: bold;
}
input.error {
  border-color: #B8272D;
}
#site-main .gform_wrapper .ginput_complex.ginput_container {
  border: none;
}
.line-balance_due {
  border: solid #aaa;
  border-width: 1px 0;
  padding: 1em 0 0.4em;
}
#cancelSecondPayment {
  margin-left: 14vw;
}
#av_guestPreCheckout,
#checkoutPaymentForm,
#secondaryCheckoutPayment {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}
#creditCardForm,
#secCreditCardForm,
#giftCertificateForm,
#secGiftCertificateForm,
#accountBalanceForm {
  flex-basis: 100%;
}
#accountBalanceForm #availableBalance {
  font-weight: 700;
}
#checkoutPaymentForm {
  margin-top: 2.4em;
}
.checkout.order-summary {
  flex-basis: 48%;
  margin-left: auto;
}
.checkout.order-summary .orderSummary li {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #dfdfdf;
  align-items: baseline;
}
.checkout.order-summary .orderSummary li h6 {
  font-weight: 400;
}
.checkout.order-summary .orderSummary li h6 a.remove-gift {
  font-size: 16px;
  margin-left: 0.6em;
}
.checkout.order-summary .orderSummary li h6 a.remove-payment {
  font-weight: 200;
}
.checkout.order-summary .orderSummary li span {
  margin-left: auto;
}
.checkout.order-summary .orderSummary li:last-child {
  margin-top: 0.6em;
  border-bottom: none;
  font-weight: 800;
}
.checkout.order-summary .orderSummary li:last-child h6 {
  font-weight: 800;
}
#shipping_info h5 {
  margin-right: 1.2em;
}
.payment_type_radio,
.sec_payment_type_radio {
  padding-top: 1.2em;
  display: grid;
  grid-template-columns: 56px 1.2em auto;
  align-items: baseline;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
}
.full-col {
  flex-basis: 100%;
  flex-shrink: 0;
}
.fieldset {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: no-wrap;
  list-style-type: none;
}
.fieldset.half-col {
  width: 100%;
}
.fieldset .field {
  list-style-type: none  !important;
  padding: 2px 4px;
  box-sizing: border-box;
  max-width: 540px;
}
.fieldset .field.inline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: flex-start;
}
.fieldset .field input[type="text"],
.fieldset .field input[type="password"] {
  width: 100%;
}
.fieldset .field input[type="text"]:disabled,
.fieldset .field input[type="select"]:disabled {
  cursor: not-allowed;
  border-color: #ddd;
}
.fieldset .field input[type="checkbox"] {
  text-align: left;
  margin-right: 18px;
}
.fieldset .field label {
  font-weight: 500;
  font-size: 16px;
}
.fieldset .field label.inline {
  display: inline-block;
}
.fieldset .field label.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.fieldset .field .field_description,
.fieldset .field .field_description_list {
  font-size: 15px;
  color: #999;
}
.fieldset .field .field_description_list {
  list-style-type: disc;
}
.fieldset .col-2,
.fieldset .col-3,
.fieldset .col-4,
.fieldset .col-5,
.fieldset .col-6,
.fieldset .col-7,
.fieldset .col-8,
.fieldset .col-9,
.fieldset .col-10,
.fieldset .col-11,
.fieldset .col-12 {
  flex-basis: 100%;
}
.fieldset .col-2.clear,
.fieldset .col-3.clear,
.fieldset .col-4.clear,
.fieldset .col-5.clear,
.fieldset .col-6.clear,
.fieldset .col-7.clear,
.fieldset .col-8.clear,
.fieldset .col-9.clear,
.fieldset .col-10.clear,
.fieldset .col-11.clear,
.fieldset .col-12.clear {
  margin-right: 100%;
}
@media (min-width: 580px) {
  .fieldset {
    flex-direction: column;
  }
  .fieldset .col-2 {
    margin: 0;
    flex-basis: 33.3333%;
  }
  .fieldset .col-3 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset .col-4 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset .col-5 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset .col-6 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset .col-7 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset .col-8 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset .col-9 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset .col-10 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .fieldset {
    flex-direction: row;
  }
  .fieldset.half-col {
    width: 48.5%;
  }
  .fieldset .col-2 {
    margin: 0;
    flex-basis: 16.6666%;
  }
  .fieldset .col-3 {
    margin: 0;
    flex-basis: 25%;
  }
  .fieldset .col-4 {
    margin: 0;
    flex-basis: 33.333%;
  }
  .fieldset .col-5 {
    margin: 0;
    flex-basis: 41.666%;
  }
  .fieldset .col-6 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset .col-7 {
    margin: 0;
    flex-basis: 58.333%;
  }
  .fieldset .col-8 {
    margin: 0;
    flex-basis: 66.666%;
  }
  .fieldset .col-9 {
    margin: 0;
    flex-basis: 75%;
  }
  .fieldset .col-10 {
    margin: 0;
    flex-basis: 83.333%;
  }
  .fieldset .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half {
    width: 48.8%;
  }
  .fieldset.half .col-2 {
    margin: 0;
    flex-basis: 33.333%;
  }
  .fieldset.half .col-3 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset.half .col-4 {
    margin: 0;
    flex-basis: 75%;
  }
  .fieldset.half .col-5 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-6 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-7 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-8 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-9 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-10 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .fieldset {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .fieldset .col-2 {
    margin: 0;
    flex-basis: 16.6666%;
  }
  .fieldset .col-3 {
    margin: 0;
    flex-basis: 25%;
  }
  .fieldset .col-4 {
    margin: 0;
    flex-basis: 33.333%;
  }
  .fieldset .col-5 {
    margin: 0;
    flex-basis: 41.666%;
  }
  .fieldset .col-6 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset .col-7 {
    margin: 0;
    flex-basis: 58.333%;
  }
  .fieldset .col-8 {
    margin: 0;
    flex-basis: 66.666%;
  }
  .fieldset .col-9 {
    margin: 0;
    flex-basis: 75%;
  }
  .fieldset .col-10 {
    margin: 0;
    flex-basis: 83.333%;
  }
  .fieldset .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half {
    width: 48.8%;
  }
  .fieldset.half .col-2 {
    margin: 0;
    flex-basis: 33.333%;
  }
  .fieldset.half .col-3 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset.half .col-4 {
    margin: 0;
    flex-basis: 75%;
  }
  .fieldset.half .col-5 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-6 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-7 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-8 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-9 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-10 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
  li.payment_type_radio,
  li.sec_payment_type_radio {
    position: relative;
  }
  li.payment_type_radio #payment_option-error,
  li.sec_payment_type_radio #payment_option-error {
    position: absolute;
    right: -3em;
    top: -2em;
  }
  #creditCardForm,
  #giftCertificateForm,
  #secCreditCardForm,
  #secGiftCertificateForm,
  #accountBalanceForm {
    flex-basis: 48.5%;
  }
}
@media (min-width: 1054px) {
  .fieldset {
    flex-direction: row;
  }
  .fieldset.half .col-2 {
    margin: 0;
    flex-basis: 25%;
  }
  .fieldset.half .col-3 {
    margin: 0;
    flex-basis: 33.333%;
  }
  .fieldset.half .col-4 {
    margin: 0;
    flex-basis: 41.666%;
  }
  .fieldset.half .col-5 {
    margin: 0;
    flex-basis: 50%;
  }
  .fieldset.half .col-6 {
    margin: 0;
    flex-basis: 58.333%;
  }
  .fieldset.half .col-7 {
    margin: 0;
    flex-basis: 66.666%;
  }
  .fieldset.half .col-8 {
    margin: 0;
    flex-basis: 75%;
  }
  .fieldset.half .col-9 {
    margin: 0;
    flex-basis: 83.333%;
  }
  .fieldset.half .col-10 {
    margin: 0;
    flex-basis: 100%;
  }
  .fieldset.half .col-12 {
    margin: 0;
    flex-basis: 100%;
  }
}
.skip {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.live-video a.site-logo {
  text-align: left;
  margin: 1em;
}
.live-video .video-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.live-video .video-content > div {
  flex-basis: 100%;
  padding: 56% 0 0 0;
}
.live-video .video-content > p {
  flex-basis: 100%;
}
.live-video .video-content > p #live-chat-app form {
  margin: 0 2em;
}
.live-video .video-content p.no-break {
  flex-basis: 100%;
}
@media (min-width: 780px) {
  .live-video .video-content > div {
    flex-basis: 66%;
    padding: 0 0 0 0;
  }
  .live-video .video-content > p {
    flex-basis: 32%;
  }
}
.container,
.event__body {
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  clear: both;
  *zoom: 1;
}
.container:before,
.container:after,
.event__body:before,
.event__body:after {
  display: table;
  content: " ";
}
.container:after,
.event__body:after {
  clear: both;
}
.row {
  *zoom: 1;
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}
.row--margined {
  *zoom: 1;
  margin-bottom: 1.65em;
}
.row--margined:before,
.row--margined:after {
  display: table;
  content: " ";
}
.row--margined:after {
  clear: both;
}
.row--margined:last-of-type,
.row--margined > :last-child {
  margin-bottom: 0;
}
.row--bordered {
  *zoom: 1;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1.65em;
  margin-bottom: 1.65em;
}
.row--bordered:before,
.row--bordered:after {
  display: table;
  content: " ";
}
.row--bordered:after {
  clear: both;
}
.row--bordered:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}
#site-main {
  min-height: 70vh;
}
.page__header,
.event__header {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 1.65em;
  padding: 1em 3%;
  background-color: #262626;
  color: #ffffff;
  position: relative;
}
.page__header .title,
.page__header h1,
.event__header .title,
.event__header h1 {
  margin: 0;
  color: #ffffff;
}
.top-bar {
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.6);
  height: 50px;
  background-color: #262626;
  color: #ffffff;
  width: 100%;
}
.top-bar__ctas {
  float: right;
  padding: 3px 4px;
}
.top-bar__ctas > .btn {
  font-size: 14px;
  padding: 12px 14px 10px;
  margin: 3px 1px;
}
#nav-toggle {
  font-size: 14px;
  height: 50px;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  background: transparent;
  color: #ffffff;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  text-transform: uppercase;
}
#nav-toggle .icon {
  vertical-align: -2.5px;
}
#site-header {
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  max-width: 100%;
  background-color: #ffffff;
}
#site-main {
  margin-top: 50px;
}
.site-header__top {
  padding: 0.825em 5% 0.4125em;
}
.site-logo {
  display: block;
  text-decoration: none;
  border: none;
  text-align: center;
}
.site-logo:hover,
.site-logo:focus {
  border: none;
}
.site-logo:hover #court-square,
.site-logo:focus #court-square {
  fill: #811b1f;
}
.site-logo > svg {
  max-width: 100%;
}
.site-logo #court-square {
  fill: #B8272D;
}
.site-logo #court-bg {
  fill: #ffffff;
}
.site-logo #theatre {
  fill: #000000;
}
#site-header .site-logo {
  margin: 0 auto 10px;
  width: 200px;
  height: 108px;
}
.top-bar .site-logo {
  display: inline-block;
  vertical-align: top;
  width: 78px;
  height: 42px;
  padding: 4px 0;
  position: relative;
}
.top-bar .site-logo #theatre {
  fill: #ffffff;
}
.site-header__ctas {
  text-align: center;
  margin-top: 0.4125em;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 0.825em 0;
}
.site-header__phone {
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-size: 0.9375em;
  font-weight: bold;
  margin-bottom: 0;
}
.site-header__phone a {
  color: inherit;
  border: none;
}
@media only screen and (max-width: 23em) {
  #nav-toggle {
    padding: 10px 8px 10px 10px;
  }
  .top-bar .site-logo {
    width: 60px;
    height: 35px;
    padding-top: 8px;
  }
  .top-bar__ctas > .btn {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (max-width: 53.9375em) {
  :target:before {
    content: "";
    display: block;
    height: 50px;
    margin: -50px 0 0;
  }
  #site-header {
    position: relative;
    top: 50px;
  }
  .js #site-header {
    position: fixed;
    z-index: 102;
    width: 18em;
    transform: translateX(-18em);
    transition: transform ease-out 0.2s;
  }
  #site-header.opened {
    transform: translate(0);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  }
  .site-header__ctas > .btn {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (min-width: 54em) {
  .top-bar {
    display: none;
    visibility: hidden;
  }
  #site-header {
    width: 25%;
    box-shadow: none;
  }
  #site-header .site-logo {
    width: auto;
    height: auto;
    margin: 15px 0;
  }
  #site-main,
  #site-footer {
    width: 75%;
    margin-left: 25%;
    margin-top: 0;
  }
  .live-video #site-main,
  .live-video #site-footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding: 1em 30px;
  }
  .site-header__phone {
    margin-bottom: 0.825em;
  }
  .site-header__ctas > .btn {
    font-size: 13px;
    font-size: 0.8125em;
    margin: 0 2px;
    padding: 9px;
  }
}
@media only screen and (min-width: 65em) {
  .site-header__ctas > .btn {
    font-size: 15px;
    font-size: 0.9375em;
    padding: 10px 15px;
  }
}
#site-footer {
  margin-top: 3.3em;
  padding: 1.65em 0;
  background-color: #262626;
  color: #ffffff;
  font-size: 0.875em;
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  #site-footer {
    font-size: calc(14px  +  1  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  #site-footer {
    font-size: 15px;
  }
}
#site-footer a:link,
#site-footer a:visited {
  color: #ffffff;
}
#site-footer a:hover,
#site-footer a:focus {
  color: #B8272D;
}
#uchicago-arts-signature {
  display: block;
  width: 33%;
  border: none;
  margin-bottom: 1.65em;
}
#uchicago-arts-signature > svg {
  fill: #ffffff;
  height: auto;
}
#uchicago-arts-signature:hover > svg,
#uchicago-arts-signature:focus > svg {
  fill: #939597;
}
.social-links {
  clear: both;
}
.social-links li {
  display: inline-block;
  margin-right: 0.5em;
}
.textwidget p {
  font-size: 0.9em;
}
#site-footer .social-links a {
  display: block;
  border: none;
  color: #ffffff;
}
#site-footer .social-links a:hover,
#site-footer .social-links a:focus {
  color: #B8272D;
}
#email-address {
  width: 18em;
}
@media only screen and (min-width: 23em) {
  .social-links {
    float: right;
    clear: none;
  }
  .site-footer__contact address {
    float: right;
    margin-left: 3em;
    text-align: right;
  }
}
@media only screen and (min-width: 52em) {
  .social-links {
    float: none;
    clear: both;
  }
  .site-footer__contact {
    text-align: right;
  }
  #uchicago-arts-signature {
    width: 66%;
    margin-bottom: 0.825em;
  }
}
/* Navigation */
.nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav a {
  border-bottom: none;
}
.main-nav {
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 18px;
  padding-bottom: 1.65em;
}
@media screen and (min-width: 320px) {
  .main-nav {
    font-size: calc(18px  +  -2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .main-nav {
    font-size: 16px;
  }
}
.main-nav li > a {
  float: none;
  padding: 0.35em 10%;
  background-color: transparent;
  color: #B8272D;
  text-decoration: none;
  display: block;
}
.main-nav li > a:hover,
.main-nav li > a:focus {
  color: #ffffff;
  text-decoration: none;
  text-shadow: none;
  background-color: #811b1f;
}
.main-nav .current > a,
.main-nav .current > a:hover,
.main-nav .current > a:focus {
  color: #404040;
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}
.main-nav li ul {
  font-size: 0.9375em;
}
.main-nav li ul li a {
  padding: 0.35em 10% 0.35em 15%;
}
.main-nav li ul li ul li a {
  padding-left: 25%;
}
.nav__search {
  position: relative;
  margin-bottom: 0;
}
.nav__search input[type='text'],
.nav__search input[type='search'] {
  float: left;
  margin-bottom: 0;
  padding: 4px 27px 4px 14px;
  border-radius: 30px;
}
.nav__search button[type='submit'] {
  float: left;
  width: 25px;
  height: 25px;
  border: none;
  background: none;
  margin-left: -27px;
  margin-top: 1px;
}
.col--major + .col--minor,
.col--minor + .col--major,
.col--half + .col--half {
  margin-top: 1.65em;
}
@media only screen and (min-width: 40em) {
  .col--third {
    float: left;
    width: calc(50% - (30px * 2));
    margin-right: 30px;
    margin-left: 30px;
  }
  .col--third:nth-of-type(1n) {
    width: calc(50% - 30px);
    margin-right: calc(30px * 2);
    margin-left: 0;
  }
  .col--third:nth-of-type(2n) {
    width: calc(50% - 30px);
    margin-right: 0;
    margin-left: 0;
  }
  .col--third:nth-of-type(2n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 52em) {
  .col--half {
    float: left;
    width: calc(50% - (30px * 2));
    margin-right: 30px;
    margin-left: 30px;
  }
  .col--half:nth-of-type(1n) {
    width: calc(50% - 30px);
    margin-right: calc(30px * 2);
    margin-left: 0;
  }
  .col--half:nth-of-type(2n) {
    width: calc(50% - 30px);
    margin-right: 0;
    margin-left: 0;
  }
  .col--half:nth-of-type(2n+1) {
    clear: left;
  }
  .col--third {
    float: left;
    width: calc(33.333% - 30px);
    margin-right: calc(30px / 2);
    margin-left: calc(30px / 2);
  }
  .col--third:nth-of-type(1n) {
    width: 32%;
    margin-right: 2%;
    margin-left: 0;
  }
  .col--third:nth-of-type(2n) {
    width: 32%;
    margin-right: 2%;
    margin-left: 0;
  }
  .col--third:nth-of-type(3n) {
    width: 32%;
    margin-right: 0;
    margin-left: 0;
  }
  .col--third:nth-of-type(2n+1) {
    clear: none;
  }
  .col--third:nth-of-type(3n+1) {
    clear: left;
  }
  .col--major {
    float: left;
    width: calc(62% - 30px/2);
  }
  .col--minor {
    float: left;
    width: calc(38% - 30px/2);
  }
  .col--major + .col--minor,
  .col--minor + .col--major {
    margin-top: 0;
    margin-left: 30px;
  }
  .col--half + .col--half {
    margin-top: 0;
  }
}
.col--solo,
.col--solo--centered {
  display: block;
  clear: both;
  max-width: 40em;
}
.col--solo--centered {
  float: none;
  margin-right: auto;
  margin-left: auto;
}
/*
.col--video {
  display: block;
  clear: both;
  max-width: 34em;
}
*/
/*
  the container for the items;
  needs a min class and a max class on it 
  - min classes: .min1, .min2, .min3
  - max classes: .max3, .max4, .max5, .max6
*/
.grid-container {
  list-style: none;
  margin: 0 -2%;
  padding: 0;
  *zoom: 1;
}
.grid-container:before,
.grid-container:after {
  display: table;
  content: " ";
}
.grid-container:after {
  clear: both;
}
/* each item */
.grid-item {
  display: inline;
  float: left;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 0.825em;
}
/* below smallest breakpoint */
.min1 .grid-item {
  width: 96%;
}
.min2 .grid-item {
  width: 46%;
}
.min3 .grid-item {
  width: 29.333%;
}
.min2 .grid-item:nth-of-type(2n+1),
.min3 .grid-item:nth-of-type(3n+1) {
  clear: left;
}
@media only screen and (min-width: 30em) {
  /* 
  max6 and max5 become 3-column, unless they have min1,
  in which case they become 2-column. All else stay at
  their minimum.
*/
  .max6 .grid-item,
  .max5 .grid-item {
    width: 29.333%;
  }
  .min1 .grid-item {
    width: 46%;
  }
  .max6 .grid-item:nth-of-type(3n+1),
  .max5 .grid-item:nth-of-type(3n+1),
  .min1 .grid-item:nth-of-type(2n+1) {
    clear: left;
  }
  .min1 .grid-item:nth-of-type(3n+1) {
    clear: none;
  }
}
@media only screen and (min-width: 45em) {
  /*
  all become 3-column.
*/
  .max6 .grid-item,
  .max5 .grid-item,
  .max4 .grid-item,
  .max3 .grid-item {
    width: 29.333%;
  }
  .min1 .grid-item:nth-of-type(2n+1),
  .min2 .grid-item:nth-of-type(2n+1) {
    clear: none;
  }
  .max6 .grid-item:nth-of-type(3n+1),
  .max5 .grid-item:nth-of-type(3n+1),
  .max4 .grid-item:nth-of-type(3n+1),
  .max3 .grid-item:nth-of-type(3n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 55em) {
  /*
  max6 and max5 become 4-column.
*/
  .max6 .grid-item,
  .max5 .grid-item {
    width: 21%;
  }
  .max6 .grid-item:nth-of-type(3n+1),
  .max5 .grid-item:nth-of-type(3n+1),
  .max3 .grid-item:nth-of-type(3n+1) {
    clear: none;
  }
  .max6 .grid-item:nth-of-type(4n+1),
  .max5 .grid-item:nth-of-type(4n+1),
  .max3 .grid-item:nth-of-type(3n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 60em) {
  /*
  max6 and max5 become 5-column. max4 becomes 4-column.
*/
  .max6 .grid-item,
  .max5 .grid-item {
    width: 16%;
  }
  .max4 .grid-item {
    width: 21%;
  }
  .max6 .grid-item:nth-of-type(4n+1),
  .max5 .grid-item:nth-of-type(4n+1),
  .max4 .grid-item:nth-of-type(3n+1) {
    clear: none;
  }
  .max6 .grid-item:nth-of-type(5n+1),
  .max5 .grid-item:nth-of-type(5n+1),
  .max4 .grid-item:nth-of-type(4n+1) {
    clear: left;
  }
}
@media only screen and (min-width: 70em) {
  /*
  max6 becomes 6-column.
*/
  .max6 .grid-item {
    width: 12.666%;
  }
  .max6 .grid-item:nth-of-type(5n+1) {
    clear: none;
  }
  .max6 .grid-item:nth-of-type(6n+1) {
    clear: left;
  }
}
.alert {
  margin-bottom: 1.65em;
  padding: 1em;
  background-color: #fcf8e3;
  border: 1px solid #f2e187;
  border-radius: 4px;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  color: #9c8611;
}
.alert > *:last-child {
  margin-bottom: 0;
}
.alert__heading {
  font-size: 18px;
  font-size: 1.125em;
  color: inherit;
}
.alert--success {
  background-color: #c5e6c5;
  border-color: #a2d6a2;
  color: #347834;
}
.alert--warning {
  background-color: #fee7c7;
  border-color: #fcd296;
  color: #c17305;
}
.alert--danger {
  background-color: #f4cac8;
  border-color: #eba19e;
  color: #da4f49;
}
.alert--info {
  background-color: #d9edf7;
  border-color: #ffffff;
  color: #247fad;
}
.alert--lesser {
  background-color: #ededed;
  border-color: #d9d9d9;
  color: #b4b4b4;
}
.alert--block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert--block .alert__heading {
  margin-bottom: 0.20625em;
  font-size: inherit;
  font-weight: bold;
}
.alert--block > p,
.alert--block > ul {
  margin-bottom: 0;
}
.alert--block p + p {
  margin-top: 5px;
}
.browserupgrade {
  margin: 0;
  background: #fee7c7;
  color: #c17305;
  padding: 0.5em 2.5%;
}
.banner {
  margin: 0.825em 0.825em 1.65em;
  padding: 1.1em 4%;
  border: 1px solid #999999;
  background: #ededed;
  font-size: 16px;
  *zoom: 1;
}
@media screen and (min-width: 320px) {
  .banner {
    font-size: calc(16px  +  4  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .banner {
    font-size: 20px;
  }
}
.banner:before,
.banner:after {
  display: table;
  content: " ";
}
.banner:after {
  clear: both;
}
@media only screen and (max-width: 39.9735em) {
  .banner__cta {
    text-align: center;
  }
}
@media only screen and (min-width: 40em) {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner p:last-child,
  .banner blockquote:last-child {
    margin-bottom: 0;
  }
  .banner__text {
    margin-right: 5%;
  }
  .banner__cta {
    text-align: right;
    margin-top: 0;
    flex-shrink: 0;
  }
  .no-flexbox  .banner__text {
    float: left;
    width: 75%;
    margin-right: 2%;
  }
  .no-flexbox  .banner__cta {
    float: right;
    width: 23%;
  }
}
/* Buttons =================================================== */
.btn {
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 0;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  line-height: 1.2375em;
  color: #444;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  background-color: #d9d9d9;
  border: 0 none #c3c3c3;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}
.btn:visited,
.btn:hover,
.btn:focus {
  color: #444;
  text-decoration: none;
}
.btn:hover,
.btn:focus {
  background-color: #c3c3c3;
  color: #444;
}
.btn small {
  font-weight: normal;
  line-height: 1;
}
.btn:first-child {
  *margin-left: 0;
}
.btn--disabled {
  opacity: 0.5;
  cursor: default;
}
.btn--link {
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: #B8272D;
  padding: 0;
  line-height: normal;
  vertical-align: baseline;
}
.btn--link:visited {
  color: #B8272D;
}
.btn--link:hover,
.btn--link:focus,
.btn--link:active {
  background: transparent;
  color: #811b1f;
  text-decoration: underline;
}
.btn--primary {
  background-color: #B8272D;
  border-color: #8a1d22;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  letter-spacing: 0.5px;
}
.btn--primary:visited,
.btn--primary:hover,
.btn--primary:focus {
  color: #ffffff;
}
.btn--primary:hover,
.btn--primary:focus {
  background-color: #931f24;
}
.btn--extlarge {
  padding: 20px 35px;
  font-size: 22.5px;
  font-size: 1.40625em;
}
.btn--large {
  padding: 15px 25px;
  font-size: 19.8px;
  font-size: 1.2375em;
}
.btn--small {
  padding: 6px 10px;
  font-size: 13px;
  font-size: 0.8125em;
}
.btn--mini {
  padding: 3px 5px;
  font-size: 11px;
  font-size: 0.6875em;
}
.btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}
.btn--block + .btn--block {
  margin-top: 5px;
}
input[type="submit"].btn--block,
input[type="reset"].btn--block,
input[type="button"].btn--block {
  width: 100%;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
svg.icon {
  fill: currentColor;
}
.icon--textsize {
  width: 1em;
  height: 1em;
}
.icon--small {
  width: 16px;
  height: 16px;
}
.icon--medium {
  width: 24px;
  height: 24px;
}
.icon--large {
  width: 32px;
  height: 32px;
}
.icon--xlarge {
  width: 48px;
  height: 48px;
}
/*
i.icon {
  font-style: normal;
}
*/
/*
a[href$=".pdf"]:before {
  font-family: "FontAwesome";
  content: "\f1c1";
  margin-right: 0.25em;
}
a[href$=".doc"]:before {
  font-family: "FontAwesome";
  content: "\f1c2";
  margin-right: 0.25em;
}
a[href$=".docx"]:before {
  font-family: "FontAwesome";
  content: "\f1c2";
  margin-right: 0.25em;
}
a[href$=".xls"]:before {
  font-family: "FontAwesome";
  content: "\f1c3";
  margin-right: 0.25em;
}
a[href$=".xlsx"]:before {
  font-family: "FontAwesome";
  content: "\f1c3";
  margin-right: 0.25em;
}
a[href$=".ppt"]:before {
  font-family: "FontAwesome";
  content: "\f1c4";
  margin-right: 0.25em;
}
a[href$=".pptx"]:before {
  font-family: "FontAwesome";
  content: "\f1c4";
  margin-right: 0.25em;
}
a[href$=".jpg"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".jpeg"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".gif"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".png"]:before {
  font-family: "FontAwesome";
  content: "\f1c5";
  margin-right: 0.25em;
}
a[href$=".txt"]:before {
  font-family: "FontAwesome";
  content: "\f0f6";
  margin-right: 0.25em;
}
*/
.person__name {
  text-transform: uppercase;
  font-weight: bold;
}
@media only screen and (max-width: 29.9375em) {
  .person__img {
    display: block;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 30em) {
  .person__img {
    float: left;
    margin-right: 30px;
    margin-bottom: 1.65em;
    max-width: 200px;
  }
}
@media only screen and (min-width: 65em) {
  .person__bio {
    margin-left: 230px;
    max-width: 40em;
  }
}
.blog-teaser {
  border-top: 1px solid #d9d9d9;
  padding: 1.65em 0 0;
  margin-bottom: 0.825em;
  *zoom: 1;
  font-size: 16px;
}
.blog-teaser:before,
.blog-teaser:after {
  display: table;
  content: " ";
}
.blog-teaser:after {
  clear: both;
}
@media screen and (min-width: 320px) {
  .blog-teaser {
    font-size: calc(16px  +  2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .blog-teaser {
    font-size: 18px;
  }
}
.blog-teaser .title {
  text-transform: none;
  font-size: 21px;
}
@media screen and (min-width: 320px) {
  .blog-teaser .title {
    font-size: calc(21px  +  3  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .blog-teaser .title {
    font-size: 24px;
  }
}
.event .blog-teaser {
  border: none;
  padding: 0;
}
.blog-teaser__img {
  display: block;
  margin: 0 auto 0.825em;
}
.blog-teaser__body {
  font-size: 15px;
  font-size: 0.9375em;
}
@media only screen and (min-width: 23em) {
  .blog-teaser__img {
    float: left;
    max-width: 100px;
    margin-right: 15px;
  }
}
@media only screen and (min-width: 30em) {
  .blog-teaser__img {
    float: left;
    max-width: 200px;
  }
  .blog-teaser__body {
    margin-left: 215px;
  }
  .blog-teaser__body:first-child {
    margin-left: 0;
  }
}
h2.tribe-events-page-title a {
  color: #fff;
}
h2.tribe-events-page-title a:hover,
h2.tribe-events-page-title a:focus {
  color: #fff;
}
#slider,
#carousel {
  margin-bottom: 0;
  box-shadow: none;
  background-color: #262626;
}
#slider .flex-direction-nav a,
#carousel .flex-direction-nav a {
  border: none;
  box-sizing: border-box;
  padding-top: 8px;
  text-shadow: none;
}
#slider .flex-direction-nav a:before,
#carousel .flex-direction-nav a:before {
  color: rgba(255, 255, 255, 0.6);
}
#slider .slides {
  display: flex;
  align-items: center;
}
#slider .slides img {
  max-height: 400px;
  max-height: 65vh;
  width: auto;
  margin: 0 auto;
}
#slider .caption {
  margin: 0;
  padding: 1em;
  color: #ddd;
}
#slider .caption:empty {
  padding: 0;
}
#carousel .slides > li {
  height: 140px;
}
.page-numbers {
  display: inline-block;
  padding: 0.25em 0.75em;
}
.page-numbers.current {
  font-weight: bold;
}
a.page-numbers {
  border: 1px solid #ededed;
  border-radius: 0;
  text-decoration: none;
}
a.page-numbers:hover,
a.page-numbers:focus {
  background-color: #811b1f;
  color: #ffffff;
}
.entry-content img,
.single .wp-post-image {
  margin-bottom: 1.65em;
}
.alignleft,
img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
}
.alignright,
img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
}
.aligncenter,
img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
}
.wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;
}
.wp-caption img {
  border: 0 none;
  padding: 0;
  margin: 0;
}
.wp-caption p.wp-caption-text {
  line-height: 1.5;
  font-size: 10px;
  margin: 0;
}
.wp-smiley {
  margin: 0 !important;
  max-height: 1em;
}
blockquote.left {
  margin-right: 20px;
  text-align: right;
  margin-left: 0;
  width: 33%;
  float: left;
}
blockquote.right {
  margin-left: 20px;
  text-align: left;
  margin-right: 0;
  width: 33%;
  float: right;
}
/* == WordPress CSS - Miscellaneous Styles == */
/* category links */
/* blogroll links */
/* read-more links */
@media only screen and (max-width: 29.9375em) {
  .alignleft,
  img.alignleft,
  .alignright,
  img.alignright {
    float: none;
    clear: both;
    display: block;
    margin: 0 auto 0.825em;
  }
}
.feature,
.feature--primary,
.feature--secondary,
.feature--tertiary {
  margin-bottom: 1.65em;
  clear: both;
  *zoom: 1;
}
.feature:before,
.feature:after,
.feature--primary:before,
.feature--primary:after,
.feature--secondary:before,
.feature--secondary:after,
.feature--tertiary:before,
.feature--tertiary:after {
  display: table;
  content: " ";
}
.feature:after,
.feature--primary:after,
.feature--secondary:after,
.feature--tertiary:after {
  clear: both;
}
.feature__img {
  width: 100%;
}
.feature__body {
  box-sizing: border-box;
  padding: 1.1em 5%;
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .feature__body {
    font-size: calc(14px  +  2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .feature__body {
    font-size: 16px;
  }
}
.feature__body p,
.feature__body ul,
.feature__body ol,
.feature__body blockquote {
  margin-bottom: 0.4125em;
}
.feature__body > *:last-child {
  margin-bottom: 0;
}
h2.feature__title {
  margin-bottom: 0.20625em;
}
h2.feature__title a {
  color: #B8272D;
}
.feature__cta {
  margin-top: 0.825em;
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .feature__cta {
    font-size: calc(14px  +  2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .feature__cta {
    font-size: 16px;
  }
}
.feature__cta .btn {
  padding: 8px 12px;
}
.feature__cta .btn + a {
  margin-left: 0.5em;
}
.feature--white {
  border-bottom: 6px solid #404040;
}
.feature--white > .feature__body {
  background: #fff;
}
.feature--black > .feature__body {
  background: #000;
}
.feature--black,
.feature--black .feature__title {
  color: #fff;
}
.feature--primary > .feature__body {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .feature--primary > .feature__body {
    font-size: calc(16px  +  4  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .feature--primary > .feature__body {
    font-size: 20px;
  }
}
.feature--primary .feature__title,
.feature--primary h2.feature__title {
  font-size: 24px;
  line-height: 1;
}
@media screen and (min-width: 320px) {
  .feature--primary .feature__title,
  .feature--primary h2.feature__title {
    font-size: calc(24px  +  40  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .feature--primary .feature__title,
  .feature--primary h2.feature__title {
    font-size: 64px;
  }
}
.feature--primary .feature__title > a,
.feature--primary h2.feature__title > a {
  color: inherit;
  border: none;
}
.feature--secondary {
  border: 1px solid #d9d9d9;
}
.feature--tertiary {
  padding: 0 5%;
  border-bottom: 1px solid #d9d9d9;
}
.feature--tertiary > a .feature__img {
  float: left;
  width: auto;
  max-width: 35%;
  margin: 0 15px 1.1em 0;
}
.feature--tertiary > .feature__body {
  border: none;
  padding: 0 0 0.825em;
}
.feature--tertiary .feature__title {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .feature--tertiary .feature__title {
    font-size: calc(16px  +  8  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .feature--tertiary .feature__title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 48em) {
  .feature--primary {
    position: relative;
  }
  .feature--primary > .feature__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    padding: 1em 5%;
    border: none;
  }
  .feature--white > .feature__body {
    background: #fff;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 100%);
  }
  .feature--black > .feature__body {
    background: #000;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 20%, #000000 100%);
  }
}
@media only screen and (min-width: 52em) {
  .feature--secondary {
    margin-left: 5%;
  }
  .feature--secondary > .feature__body {
    padding: 1.65em 1em;
  }
  .feature--tertiary {
    padding: 0 1em 0.5em;
    margin-right: 5%;
    margin-bottom: 1.65em;
  }
}
@media only screen and (min-width: 54em) {
  .home-container {
    padding-left: 2%;
  }
  .feature--primary > .feature__body {
    position: static;
    width: auto;
    padding: 1em;
    border: none;
  }
  .feature--white > .feature__body {
    background: #fff;
  }
  .feature--black > .feature__body {
    background: #000;
  }
}
@media only screen and (min-width: 65em) {
  .feature--primary > .feature__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    padding: 1em 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .feature--white > .feature__body {
    background: #fff;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 100%);
  }
  .feature--black > .feature__body {
    background: #000;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 20%, #000000 100%);
  }
  .feature--tertiary .feature__img + .feature__body {
    margin-left: calc(35% + 15px);
  }
}
@media only screen and (min-width: 90em) {
  .feature--primary > .feature__body {
    width: 50%;
  }
}
.event {
  position: relative;
}
.img--full {
  width: 100%;
}
.event__header {
  padding-top: 2.4em;
}
.event__body {
  background-color: #ffffff;
}
.photo-galleries p {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .photo-galleries p {
    font-size: calc(14px  +  2  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .photo-galleries p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 30em) {
  .event__header__cta {
    padding: 10px 25px;
  }
}
@media only screen and (min-width: 70em) {
  .event__header {
    *zoom: 1;
    padding-bottom: 1px;
  }
  .event__header:before,
  .event__header:after {
    display: table;
    content: " ";
  }
  .event__header:after {
    clear: both;
  }
  .event__header__major,
  .event__header__minor {
    float: left;
  }
  .event__header__major {
    width: 62%;
  }
  .event__header__minor {
    width: 38%;
    text-align: right;
  }
}
.video-item {
  margin-bottom: 0.825em;
}
@media only screen and (min-width: 35em) {
  .videos-container {
    *zoom: 1;
    margin-left: -2%;
    margin-right: -2%;
  }
  .videos-container:before,
  .videos-container:after {
    display: table;
    content: " ";
  }
  .videos-container:after {
    clear: both;
  }
  .video-item {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
    float: left;
  }
  .video-item:nth-of-type(2n+1) {
    clear: left;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teaser-collection {
  margin-bottom: 1.65em;
  overflow: hidden;
}
h2 + .teaser-collection {
  margin-top: -0.6703125em;
}
.event-teaser {
  *zoom: 1;
  padding: 0.4125em 1em;
  margin-bottom: 1.65em;
  border: 1px solid #d9d9d9;
  font-size: 13px;
}
.event-teaser:before,
.event-teaser:after {
  display: table;
  content: " ";
}
.event-teaser:after {
  clear: both;
}
@media screen and (min-width: 320px) {
  .event-teaser {
    font-size: calc(13px  +  3  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser {
    font-size: 16px;
  }
}
.event-teaser .title {
  font-size: 21px;
  font-size: 1.3125em;
  font-size: 22px;
  float: left;
  width: 62%;
  box-sizing: border-box;
  padding-right: 10px;
  margin-bottom: 0;
}
@media screen and (min-width: 320px) {
  .event-teaser .title {
    font-size: calc(22px  +  8  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser .title {
    font-size: 30px;
  }
}
.event-teaser .title > a {
  border-bottom: none;
}
.event-teaser .title > a:hover,
.event-teaser .title > a:focus {
  border-bottom: 1px solid;
}
.event-teaser p {
  margin-bottom: 0.825em;
}
.event-teaser p:last-child {
  margin-bottom: 0;
}
.teaser-collection > .event-teaser {
  margin-bottom: 0;
}
.event-teaser + .event-teaser {
  border-top: none;
}
.event-teaser--compact .title,
.event-teaser--past .title {
  font-size: 1em;
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .event-teaser--compact .title,
  .event-teaser--past .title {
    font-size: calc(16px  +  4  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser--compact .title,
  .event-teaser--past .title {
    font-size: 20px;
  }
}
.event-teaser--full,
.event-teaser--current {
  padding: 1.1em 1em;
}
.event-teaser--full .title,
.event-teaser--current .title {
  font-size: 26px;
  font-size: 1.625em;
  font-size: 28px;
  margin-bottom: 0.20625em;
}
@media screen and (min-width: 320px) {
  .event-teaser--full .title,
  .event-teaser--current .title {
    font-size: calc(28px  +  8  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser--full .title,
  .event-teaser--current .title {
    font-size: 36px;
  }
}
.event-teaser--past {
  background-color: #ededed;
  opacity: 0.75;
}
.event-teaser--past .event-teaser__dates {
  font-size: 14px;
  font-size: 0.875em;
}
.teaser-collection .event-teaser--current {
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.5);
}
.event-teaser--future {
  padding-top: 0.825em;
  padding-bottom: 0.825em;
}
.event-teaser__dates {
  float: right;
  width: 38%;
  text-align: right;
  font-size: 16px;
  font-size: 1em;
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  .event-teaser__dates {
    font-size: calc(14px  +  4  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser__dates {
    font-size: 18px;
  }
}
.event-teaser__top-billing {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875em;
  font-size: 13px;
  line-height: 1.35;
}
@media screen and (min-width: 320px) {
  .event-teaser__top-billing {
    font-size: calc(13px  +  3  * ((100vw -  320px ) / 1120 ));
  }
}
@media screen and (min-width: 1440px) {
  .event-teaser__top-billing {
    font-size: 16px;
  }
}
.event-teaser__image {
  float: left;
  width: 40%;
  margin: 0 10px 10px 0;
}
.event-teaser__ctas {
  text-align: right;
}
.event-teaser__ctas .btn {
  margin-left: 1em;
  padding: 8px 15px;
}
@media only screen and (min-width: 40em) {
  .event-teaser__image {
    width: 33%;
  }
}
html,
body {
  height: 100%;
}
.site_error_alert {
  position: absolute;
  z-index: 104;
  top: 30%;
  margin: auto auto;
  width: 400px;
  background: #fff;
  height: 400px;
  display: flex;
  align-items: center;
  padding: 2em;
  border: solid red;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 26px;
  box-sizing: border-box;
}
#courtTheatre_seatmap {
  background-image: url(/wp-content/themes/ct-2017/dist/images/map_background.jpeg);
  background-repeat: no-repeat;
  background-size: 98.3%;
  background-position: left -3px top 0;
}
.seat-select {
  display: block;
  max-width: 62em;
  margin: 0.2em auto 0.6em;
  position: relative;
}
.seat-select #zoomControls {
  position: absolute;
  z-index: 32;
  top: 12px;
  right: 12px;
}
.seat-select #zoomControls button {
  font-size: 12px;
  min-width: 32px;
  padding: 4px;
}
.seat-info {
  height: 5em;
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.seat-info .seatDesc {
  flex-basis: 33%;
}
.seat-info .seatMssg {
  padding: 0 1em;
}
.btn-primary {
  background-color: #B8272D;
  color: #fff;
  margin-right: 1.2em;
}
.btn-primary:hover {
  background-color: #931f24;
  color: #fff;
}
span.error {
  color: #b8272d;
  font-weight: 600;
}
#seat_modal {
  background-color: #ededed;
  position: fixed;
  top: 12em;
  left: calc(0px + 25%);
  right: 0;
  margin: 0 auto;
  max-width: 480px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: none;
  border-radius: 4px;
  z-index: 101;
}
#seat_modal .seat-modal__close-modal {
  opacity: 0.6;
  width: 40px;
  box-sizing: border-box;
  display: block;
  height: auto;
  border: none;
  float: right;
  padding: 12px;
  fill: #cdcdcd;
}
#seat_modal h4 {
  margin: 1.4em 0.4em 1em;
}
#seat_modal .price-content input[type='radio'] {
  opacity: 0;
  position: absolute;
  left: -300px;
}
#seat_modal .price-content input[type='radio']:checked + label {
  background-color: #b8272d;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.012em;
}
#seat_modal .price-content label {
  background-color: #fff;
  font-size: 14px;
  border: solid 1px #ccc;
  margin: 4px auto;
  width: 100%;
  display: block;
  max-width: 460px;
  padding: 3px 12px;
  font-weight: normal;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}
#seat_modal .price-content label .dashicons.dashicons-tag {
  color: #b8272d;
  position: relative;
  top: 5px;
}
#seat_modal.active {
  display: block;
}
#seat_modal button[type='submit'] {
  margin: 0.8em auto 1.2em;
  display: block;
  text-align: center;
}
#seat_modal .message {
  color: #b8272d;
  text-align: center;
  font-size: 16px;
}
#av_bestAvail {
  margin: 1.2em 0;
}
#av_bestAvail .price-legend {
  margin-bottom: 1.2em;
}
div#password_resetter {
  position: absolute;
  z-index: 111;
  background: #fff;
  padding: 1em;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  left: 0;
  right: 0;
  margin: 1.6em;
  max-width: 480px;
  width: 100%;
  border-radius: 13px;
  display: none;
  top: 20%;
}
div#password_resetter form#setNewPassword button {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  display: inline-block;
  text-align: right;
}
div#password_resetter.active {
  display: block;
}
#login_modal {
  position: fixed;
  top: 33%;
  /* bottom: 0; */
  left: 0;
  right: 0;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  background: #fff;
  height: 320px;
  padding: 2em;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: -1;
  display: none;
}
#login_modal p.alert-message {
  color: #B8272D;
  font-weight: bold;
}
#login_modal.active {
  z-index: 102;
}
#login_modal #sendUserLogin.active {
  cursor: not-allowed;
}
#login_modal #sendUserLogin.active:after {
  content: ' ';
  background-image: '/wp-content/themes/ct-2017/assets/images/ajax-loader.gif';
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: contain;
}
@media only screen and (min-width: 863px) {
  #login_modal {
    left: calc(0px + 25%);
  }
  div#password_resetter {
    left: calc(0px + 25%);
    margin: auto;
  }
}
.seat-alert,
#performance_modal,
#tc_modal {
  position: fixed;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  left: calc(0px + 25%);
  right: 0;
  display: none;
  top: 20%;
  padding: 3em;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  z-index: 101;
}
.seat-alert.active,
#performance_modal.active,
#tc_modal.active {
  display: block;
}
.seat-alert p,
#performance_modal p,
#tc_modal p {
  color: #B8272D;
  font-weight: bold;
}
.seat-alert #closeLoginModal,
#performance_modal #closeLoginModal,
#tc_modal #closeLoginModal {
  margin-top: 0.6em;
}
.calendar-promo-code .message,
.promo-codes .message {
  color: #b8272d !important;
  font-size: 15px !important;
  width: 100%;
}
.delivery-methods input[type=radio] {
  margin-right: 0.4em;
}
.delivery-methods label.radio-label.inline {
  display: inline;
  font-weight: 400;
  line-height: 1.8em;
}
#tc_modal p {
  color: 080808;
  font-weight: normal;
  padding: 1em;
  border: solid 1px #dfdfdf;
}
@media (min-width: 768px) {
  .selectSeats__event {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: auto 1fr auto;
    gap: 0px 0px;
    grid-template-areas: "event_name venue_name" "seatsOnHold__body seatsOnHold__body" ". grand_total";
    margin-bottom: 1.6em;
  }
}
.seatsOnHold__body .seatsOnHold__seatMapList li {
  display: grid;
  grid-template-columns: 13% 9% 11% 40% 11% 13% 3%;
  gap: 0px;
  margin: 0.1em 0;
  background-color: #fdfdfd;
  padding: 0.4em 1.4em;
  font-size: 16px;
}
.seatsOnHold__body .seatsOnHold__seatMapList li:nth-child(even) {
  background-color: #f9f9f9;
}
.seatsOnHold__body .seatsOnHold__seatMapList li span {
  text-align: center;
}
.seatsOnHold__body .seatsOnHold__seatMapList li span:nth-child(1),
.seatsOnHold__body .seatsOnHold__seatMapList li span:nth-child(4) {
  text-align: left;
  padding-left: 0.4em;
}
.seatsOnHold__body .seatsOnHold__seatMapList li.headings {
  font-weight: bold;
  color: #b8272d;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  padding-bottom: 0.8em;
}
.options {
  margin: 1em auto;
  text-align: center;
}
.seatsOnHold__body {
  grid-area: seatsOnHold__body;
  padding-bottom: 1em;
  border-bottom: solid 1px #ededed;
}
.grand_total {
  grid-area: grand_total;
  text-align: right;
  font-weight: 800;
  padding-top: 1em;
}
.event_name {
  grid-area: event_name;
}
.venue_name {
  grid-area: venue_name;
}
.selections {
  margin-top: 1em;
  background-color: #ededed;
  padding: 1.4em 1em 0.8em;
}
.selections .titleRow {
  font-weight: 600;
  border-bottom: solid 1px #a0a0a0;
  padding-bottom: 0.4em;
  margin-bottom: 1em;
}
.selections .seat-picked {
  font-size: 16px;
}
.selections .titleRow,
.selections .seat-picked {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
}
.selections .titleRow span.listsection,
.selections .seat-picked span.listsection {
  flex-basis: 20%;
}
.selections .titleRow span.listrow,
.selections .seat-picked span.listrow,
.selections .titleRow span.listseat,
.selections .seat-picked span.listseat {
  flex-basis: 10%;
}
.selections .titleRow span.pricetype,
.selections .seat-picked span.pricetype,
.selections .titleRow span.listseatpricetype,
.selections .seat-picked span.listseatpricetype {
  flex-basis: 40%;
}
.selections .titleRow span.listamount,
.selections .seat-picked span.listamount {
  flex-basis: 20%;
}
.selections .titleRow a.seat-list__seat-remove,
.selections .seat-picked a.seat-list__seat-remove {
  margin-left: auto;
}
a.seat-list__seat-remove,
a.on-order__seat-remove,
a.on-order__seat-final-remove {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 2px;
  border-bottom: none;
  padding: 2px;
  text-decoration: none;
}
a.seat-list__seat-remove svg,
a.on-order__seat-remove svg,
a.on-order__seat-final-remove svg {
  width: 24px;
  height: auto;
}
a.seat-list__seat-remove svg path,
a.on-order__seat-remove svg path,
a.on-order__seat-final-remove svg path {
  fill: #aaa;
}
a.seat-list__seat-remove svg:hover path,
a.on-order__seat-remove svg:hover path,
a.on-order__seat-final-remove svg:hover path {
  fill: #121212;
}
.totals {
  display: flex;
  justify-content: space-between;
}
#av_bestAvailable {
  margin: 1.2em auto;
  padding: 1.2em;
}
#av_bestAvailable .bestAvailableOptions {
  margin: 1.2em;
}
#av_bestAvailable .bestAvailableOptions li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.3em 0;
  padding: 0.4em 0.8em;
  background-color: #dfdfdf;
}
#bestAvailableOptions .dashicons.dashicons-tag {
  color: #b8272d;
  position: relative;
  top: 5px;
}
button.disabled {
  cursor: not-allowed;
  color: #989898;
}
.price-legend {
  margin-top: 2.2em;
  padding: 0.8em 0;
  width: 100%;
  margin-bottom: 1.2em;
  box-sizing: border-box;
}
.price-legend .dashicons.dashicons-tag {
  color: #b8272d;
}
.price-legend h4 {
  margin-bottom: 1.2em;
}
.price-legend li {
  list-style-type: none;
  display: flex;
  margin-right: 2em;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.price-legend li:first-child {
  margin-right: 2em;
}
.price-legend li .legend-prices {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  width: 100%;
  line-height: 18px;
}
.price-legend li .legend-prices .pricerow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #dfdfdf;
  padding: 0.4em 0;
}
.price-legend li .legend-prices .pricerow:last-child {
  border-bottom: none;
}
.price-legend li span.seat-marker {
  display: inline-block;
  width: 1.66666em;
  height: 1.66666em;
  border-radius: 1.6666em;
  position: relative;
  top: 7px;
}
.price-legend .legend-items {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  align-items: center;
}
#av_shoppingCart {
  margin: 1.2em auto;
  padding: 1.2em 3em;
  border-top: 2px solid #ccc;
  transition-duration: 0.3s;
  opacity: 0;
}
#av_shoppingCart .options {
  text-align: center;
}
#av_seatmap {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
}
#av_seatmap .overlay {
  display: none;
  transition-duration: 0.3s;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 98;
}
#av_seatmap .overlay.active {
  display: block;
}
#av_bestAvail {
  min-height: 3.675em;
  position: relative;
}
#cursor_box {
  background-color: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 1em;
  border-radius: 50px;
  display: flex;
  align-items: baseline;
  z-index: 99;
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 480px;
}
#cursor_box .message {
  color: #080808;
  margin-left: 0.8em;
}
#page_modal {
  display: block;
  position: fixed;
  left: 25%;
  right: 0;
  max-width: 480px;
  margin: 0 auto;
  top: 30%;
  background-color: #fff;
  padding: 2em;
  border: solid 1px #dfdfdf;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  opacity: 0;
  z-index: -1;
}
#page_modal.active {
  z-index: 101;
}
#page_overlay {
  position: relative;
  display: none;
}
#page_overlay.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 98;
  opacity: 0.4;
}
#av_accountPreCheckout h8 {
  font-size: 14px;
  font-weight: bold;
  display: block;
}
#av_accountPreCheckout #billing_info {
  border-top: solid 1px #ddd;
}
#av_accountPreCheckout #billing_info,
#av_accountPreCheckout #email_info,
#av_accountPreCheckout #shipping_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid 1px #ddd;
  padding: 1em 0;
}
#av_accountPreCheckout #billing_info #billingAddress,
#av_accountPreCheckout #email_info #billingAddress,
#av_accountPreCheckout #shipping_info #billingAddress {
  padding-top: 6px;
}
#av_accountPreCheckout #billing_info a.change-user-data,
#av_accountPreCheckout #email_info a.change-user-data,
#av_accountPreCheckout #shipping_info a.change-user-data {
  font-size: 14px;
}
#av_accountPreCheckout #billing_info .display label,
#av_accountPreCheckout #email_info .display label,
#av_accountPreCheckout #shipping_info .display label {
  font-size: 16px;
}
#av_accountPreCheckout #billing_info .info-edit h5,
#av_accountPreCheckout #email_info .info-edit h5,
#av_accountPreCheckout #shipping_info .info-edit h5 {
  font-size: 14px;
  margin-bottom: 0.4em;
  margin-top: 6px;
  color: #444;
}
#av_accountPreCheckout #billing_info .info-edit .address,
#av_accountPreCheckout #email_info .info-edit .address,
#av_accountPreCheckout #shipping_info .info-edit .address,
#av_accountPreCheckout #billing_info .info-edit .email-address,
#av_accountPreCheckout #email_info .info-edit .email-address,
#av_accountPreCheckout #shipping_info .info-edit .email-address {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
#av_accountPreCheckout #billing_info .info-edit .address input[type=radio],
#av_accountPreCheckout #email_info .info-edit .address input[type=radio],
#av_accountPreCheckout #shipping_info .info-edit .address input[type=radio],
#av_accountPreCheckout #billing_info .info-edit .email-address input[type=radio],
#av_accountPreCheckout #email_info .info-edit .email-address input[type=radio],
#av_accountPreCheckout #shipping_info .info-edit .email-address input[type=radio] {
  flex-basis: 4%;
  margin-right: 4%;
  padding-top: 5px;
}
#av_accountPreCheckout #billing_info .info-edit .address label,
#av_accountPreCheckout #email_info .info-edit .address label,
#av_accountPreCheckout #shipping_info .info-edit .address label,
#av_accountPreCheckout #billing_info .info-edit .email-address label,
#av_accountPreCheckout #email_info .info-edit .email-address label,
#av_accountPreCheckout #shipping_info .info-edit .email-address label {
  font-weight: normal;
  font-size: calc(14px + 3 * ((100vw - 320px)/1120));
  line-height: 20px;
  margin: 8px 0;
}
#av_accountPreCheckout #billing_info .info-edit button,
#av_accountPreCheckout #email_info .info-edit button,
#av_accountPreCheckout #shipping_info .info-edit button {
  font-size: 14px;
  text-align: right;
  margin-top: 12px;
}
#av_accountPreCheckout #billing_info .info-edit form a,
#av_accountPreCheckout #email_info .info-edit form a,
#av_accountPreCheckout #shipping_info .info-edit form a {
  font-size: 14px;
  margin-top: 8px;
  display: inline-block;
  line-height: 1.2em;
}
#av_accountPreCheckout #billing_info .add-new-address,
#av_accountPreCheckout #email_info .add-new-address,
#av_accountPreCheckout #shipping_info .add-new-address,
#av_accountPreCheckout #billing_info .add-new-email,
#av_accountPreCheckout #email_info .add-new-email,
#av_accountPreCheckout #shipping_info .add-new-email {
  margin-top: 1em;
}
#av_accountPreCheckout #billing_info .add-new-address li,
#av_accountPreCheckout #email_info .add-new-address li,
#av_accountPreCheckout #shipping_info .add-new-address li,
#av_accountPreCheckout #billing_info .add-new-email li,
#av_accountPreCheckout #email_info .add-new-email li,
#av_accountPreCheckout #shipping_info .add-new-email li {
  list-style-type: none;
  font-weight: normal;
  font-size: 16px;
  float: left;
}
#av_accountPreCheckout #billing_info .add-new-address li.col-12,
#av_accountPreCheckout #email_info .add-new-address li.col-12,
#av_accountPreCheckout #shipping_info .add-new-address li.col-12,
#av_accountPreCheckout #billing_info .add-new-email li.col-12,
#av_accountPreCheckout #email_info .add-new-email li.col-12,
#av_accountPreCheckout #shipping_info .add-new-email li.col-12 {
  width: 100%;
}
#av_accountPreCheckout #billing_info .add-new-address li.col-7,
#av_accountPreCheckout #email_info .add-new-address li.col-7,
#av_accountPreCheckout #shipping_info .add-new-address li.col-7,
#av_accountPreCheckout #billing_info .add-new-email li.col-7,
#av_accountPreCheckout #email_info .add-new-email li.col-7,
#av_accountPreCheckout #shipping_info .add-new-email li.col-7 {
  width: 58.33333%;
  margin-right: 3px;
}
#av_accountPreCheckout #billing_info .add-new-address li.col-3,
#av_accountPreCheckout #email_info .add-new-address li.col-3,
#av_accountPreCheckout #shipping_info .add-new-address li.col-3,
#av_accountPreCheckout #billing_info .add-new-email li.col-3,
#av_accountPreCheckout #email_info .add-new-email li.col-3,
#av_accountPreCheckout #shipping_info .add-new-email li.col-3 {
  width: 33.3333%;
}
#av_accountPreCheckout #billing_info .add-new-address li label,
#av_accountPreCheckout #email_info .add-new-address li label,
#av_accountPreCheckout #shipping_info .add-new-address li label,
#av_accountPreCheckout #billing_info .add-new-email li label,
#av_accountPreCheckout #email_info .add-new-email li label,
#av_accountPreCheckout #shipping_info .add-new-email li label {
  font-size: 14px;
  font-weight: normal;
  margin-top: 8px;
}
.performances-on-order {
  flex-basis: 100%;
  flex-shrink: 0;
}
.performances-on-order .performances {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.performances-on-order .performances .performance {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.8em 1em;
  background-color: #efefef;
  margin: 1em 0 2em;
  border: solid 1px #dfdfdf;
}
.performances-on-order .performances .performance .performance__details .performance__name {
  font-weight: 600;
  font-size: 18px;
  margin-top: 1.625em;
}
.performances-on-order .performances .performance .performance__details .performance__date {
  font-size: 14px;
}
.performances-on-order .performances .performance .performance__venue-details {
  margin-left: auto;
}
.performances-on-order .performances .performance .performance__venue-details .venue-name {
  font-size: 18px;
}
.performances-on-order .performances .performance .performance__venue-details .venue-address {
  font-size: 14px;
}
.performances-on-order .performances .performance .final-seat-list {
  flex-basis: 98%;
  flex-shrink: 0;
  padding: 0.6em;
  background-color: #fff;
}
.performances-on-order .performances .performance .final-seat-list .final-seat {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  padding: 0.4em;
  border-bottom: solid 1px #efefef;
  font-size: 16px;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .seat-desc {
  flex-basis: 18%;
  margin-right: 2%;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .cost-amount {
  flex-basis: 10%;
  margin-right: 2%;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .seat-pricetype {
  flex-basis: 36%;
  margin-right: 4%;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .seat-pricetype a {
  float: right;
  font-size: 14px;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .fee-amount {
  flex-basis: 10%;
  margin-right: 2%;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .total-amount {
  flex-basis: 16%;
  margin-right: 2%;
}
.performances-on-order .performances .performance .final-seat-list .final-seat .remove-seat {
  margin-left: auto;
}
.add-a-gift {
  background-color: #b8272d;
  padding: 1em;
  margin-top: 0em;
  position: relative;
  flex-basis: 41%;
}
.add-a-gift h5 {
  color: #fff;
}
.add-a-gift input#addGiftDollar {
  padding-left: 32px;
  width: 6em;
}
.add-a-gift i {
  position: absolute;
  left: 1.2em;
  top: 2.44em;
  font-size: 22px;
  font-style: normal;
}
@media (min-width: 768px) {
  #av_accountPreCheckout #billing_info,
  #av_accountPreCheckout #email_info,
  #av_accountPreCheckout #shipping_info {
    flex-direction: row;
  }
  #av_accountPreCheckout #billing_info .display,
  #av_accountPreCheckout #email_info .display,
  #av_accountPreCheckout #shipping_info .display {
    flex-basis: 38.5%;
    margin-right: 3%;
  }
  #av_accountPreCheckout #billing_info .info-edit,
  #av_accountPreCheckout #email_info .info-edit,
  #av_accountPreCheckout #shipping_info .info-edit {
    flex-basis: 58.5%;
  }
}
#av_createNewAccount,
#av_checkout {
  margin: 1.2em auto;
  padding: 1.2em 3em;
  border-top: 2px solid #ccc;
  transition-duration: 0.3s;
  opacity: 0;
  display: none;
}
#av_createNewAccount h3,
#av_checkout h3 {
  margin-bottom: 1.2em;
}
#av_createNewAccount .pswd-visible,
#av_checkout .pswd-visible {
  font-size: 14px;
}
.page-template-page-avcreate_account #av_createNewAccount {
  display: block;
  opacity: 1;
  max-width: 42rem;
}
#av_account {
  margin: 1.2em auto;
  padding: 2.4em 3em;
  border-top: 2px solid #ccc;
  transition-duration: 0.3s;
  opacity: 0;
  text-align: center;
}
#av_account .button-panel {
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  padding: 3.6em 1.8em;
  margin: 1.2em auto;
  display: flex;
  justify-content: space-between;
  max-width: 380px;
}
.paymentIcon {
  width: 42px;
  height: auto;
  margin-right: 0.8em;
  align-self: flex-start;
}
.paymentIcon_placeholder {
  display: inline-block;
  width: 46px;
  margin-right: 0.8em;
}
.onHoldTimer {
  position: fixed;
  width: 140px;
  height: auto;
  border: solid 1px #B8272D;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 14px;
  top: 80px;
  right: -300px;
  box-shadow: 1px 1px 3px #000000;
  z-index: 99;
  transition-duration: 0.3s;
}
.onHoldTimer .content {
  font-size: 14px;
  text-align: center;
  line-height: 22px;
}
.onHoldTimer .timer {
  font-weight: 700;
  text-align: center;
}
.onHoldTimer.active {
  right: 12px;
}
.bundleContent {
  display: flex;
  width: 90%;
}
.bundleContent .title h3 {
  margin: 1.4em 0;
}
.bundleContent .filters {
  opacity: 0;
  display: none;
  min-height: 24em;
}
.bundleContent #displayBundles .filterError {
  margin-top: 4em;
  max-width: 66%;
  text-align: center;
}
.bundleContent #displayBundles #bundlesOnSale {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}
.bundleContent #displayBundles #bundlesOnSale li {
  margin: 0.16em 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.4em;
  background-color: #f1f1f1;
  flex-wrap: wrap;
  line-height: 42px;
}
.bundleContent #displayBundles #bundlesOnSale li .bundleSeats {
  flex-basis: 100%;
  display: block;
}
.bundleContent #displayBundles #bundlesOnSale li .bundleSeats li {
  background-color: transparent !important;
  justify-content: flex-start !important;
  flex-wrap: wrap;
}
.bundleContent #displayBundles #bundlesOnSale li .bundleSeats li label {
  flex-basis: 45%;
  font-size: 16px;
}
.bundleContent #displayBundles #bundlesOnSale li .bundleSeats li .bundleOptions {
  flex-basis: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bundleContent #displayBundles #bundlesOnSale li:nth-child(even) {
  background-color: #fafafa;
}
.bundleContent.flexSeats {
  flex-direction: column;
}
.bundleContent.reserveSeats {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.bundleContent.reserveSeats .title {
  flex-basis: 100%;
}
.bundleContent.reserveSeats .filters {
  flex-basis: 32%;
}
.bundleContent.reserveSeats .filters label {
  font-weight: 300;
  margin-top: 0.8em;
  max-width: 94%;
}
.bundleContent.reserveSeats .filters select {
  margin-bottom: 0.8em;
}
.bundleContent.reserveSeats .filters .field {
  border-bottom: solid 1px #dfdfdf;
}
.bundleContent.reserveSeats #displayBundles {
  flex-basis: 66%;
}
#page_login {
  margin-top: 3em;
}
#av_checkout .order-summary .btn-primary {
  background-color: #B8272D;
  color: #fff;
  margin-right: 1.2em;
}
@media (min-width: 768px) {
  .seat-legend .legend-items {
    flex-direction: row;
  }
  #av_seatmap {
    padding: 1.2em 3em;
  }
  .price-legend li {
    margin-right: 3.9em;
  }
  .price-legend li:first-child {
    margin-right: 4.4em;
  }
  .price-legend li .legend-prices {
    font-size: 14px;
    margin-left: 38px;
  }
}
.accountMenuWrapper {
  float: right;
  position: relative;
  clear: both;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.accountMenuWrapper #account_menu {
  overflow: hidden;
  height: inherit;
  max-height: 0;
  transition-duration: 0.3s;
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  min-width: 12em;
  top: 2.8em;
  z-index: 4;
  border: solid 1px transparent;
  display: block;
  background-color: #fff;
}
.accountMenuWrapper #account_menu li a {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  border-bottom: none;
  display: block;
  width: 100%;
  padding: 0.4em 0.6em;
}
.accountMenuWrapper #account_menu li a:hover {
  border-bottom: none;
  background-color: #dfdfdf;
}
.accountMenuWrapper #account_menu.active {
  max-height: 1000px;
  border: solid 1px #dfdfdf;
}
.accountMenuWrapper #account_menu.closed {
  display: none;
}
#changeDefault .options {
  text-align: left;
}
#changeDefault .options .address,
#changeDefault .options .contact {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  text-align: left;
}
#changeDefault .options .address input[type='radio'],
#changeDefault .options .contact input[type='radio'] {
  margin-right: 1em;
}
#changeDefault .options .address label,
#changeDefault .options .contact label {
  font-weight: normal;
}
#login_modal {
  opacity: 0;
}
#communication_prefs_form .field {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
#communication_prefs_form .field input[type='checkbox'] {
  margin-right: 1em;
}
#communication_prefs_form .field label {
  flex-basis: 70%;
  font-weight: normal;
}
.addItem,
#changeDefaultContact,
#changeDefaultAddress {
  font-size: 14px;
}
#changeDefaultAddress,
#changeDefaultContact {
  float: right;
}
.default-selection,
.account-alert {
  opacity: 0;
  position: fixed;
  max-width: 400px;
  margin: 0 auto;
  padding: 2em;
  top: 20%;
  z-index: 1001;
  background-color: #fff;
  border: solid 1px #eee;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
}
#accountModalOverlay {
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.accountContent .accountContentInner .panel {
  margin-bottom: 3.6em;
}
.accountContent .accountContentInner .panel li {
  list-style-type: none;
  padding: 0.4em 0.9em;
  background-color: #eee;
  margin-bottom: 0.2em;
}
.accountContent .accountContentInner .panel li .item-options {
  float: right;
}
.accountContent .accountContentInner .panel .editForm li {
  background-color: transparent;
}
.accountContent .accountContentInner h4 {
  margin-top: 1em;
}
.purchase-confirmation .section-header {
  flex-basis: 100%;
  width: 100%;
}
.purchase-confirmation h5 {
  font-size: 1.24em;
}
.purchase-confirmation .label {
  font-weight: bold;
}
.purchase-confirmation .confirm-header {
  margin-top: 1.6em;
  font-size: 16px;
}
.purchase-confirmation .confirm-header .purchase-info ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #eee;
}
.purchase-confirmation .confirm-header .purchase-info ul li {
  padding: 0.6em;
}
.purchase-confirmation .confirm-header .purchase-info h4 {
  margin-top: 1.6em;
}
.purchase-confirmation .confirm-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: 1.4em;
  font-size: 16px;
}
.purchase-confirmation .confirm-body .customer-info {
  flex-basis: 100%;
  margin-right: 0%;
  padding-right: 1.6em;
}
.purchase-confirmation .confirm-body .customer-info ul {
  list-style-type: none;
  padding-left: 0;
}
.purchase-confirmation .confirm-body .customer-info ul li.address {
  margin: 1.6em 0;
}
.purchase-confirmation .confirm-body .customer-info ul li.address .label {
  display: block;
}
.purchase-confirmation .confirm-body .customer-info ul li.address span {
  display: block;
}
.purchase-confirmation .confirm-body .email-info ul {
  list-style-type: none;
  padding-left: 0;
}
.purchase-confirmation .confirm-body .email-info ul li {
  margin-bottom: 1.6em;
}
.purchase-confirmation .performances {
  border-bottom: solid 1px #cfcfcf;
  border-top: solid 1px #cfcfcf;
  margin: 0.4em 0 1.6em;
  padding-left: 0;
  padding-right: 0;
}
.purchase-confirmation .performances .performance-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 16px;
  padding-top: 1.4em;
}
.purchase-confirmation .performances .performance-header .performance-details h5 {
  color: #b8272d;
}
.purchase-confirmation .performances .seats {
  list-style-type: none;
  padding-left: 0;
  font-size: 16px;
  max-width: 42em;
  margin: 1em 0 1.8em;
  display: flex;
}
.purchase-confirmation .performances .seats li {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  margin: 0.1em 0;
  padding: 0.6em 0;
  line-height: 1.8em;
}
.purchase-confirmation .performances .seats li.seat-header {
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  flex-shrink: 0;
  padding-right: 2px;
}
.purchase-confirmation .performances .seats li .seats-inner {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.purchase-confirmation .performances .seats li .seats-inner li {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  margin: 0;
  padding: 0 0.6em;
  margin-right: 0.6em;
}
.purchase-confirmation .performances .seats li .seats-inner li.seat span {
  padding: 0 0.2em;
}
.purchase-confirmation .performances .seats li .seats-inner li.seat:nth-child(odd) {
  background-color: #efefef;
}
.purchase-confirmation .confirmation-footer {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.purchase-confirmation .confirmation-footer .payment-info ul {
  list-style-type: none;
  padding-left: 0;
}
.purchase-confirmation .confirmation-footer .charges-final {
  margin-top: 1.4em;
}
.purchase-confirmation .confirmation-footer .charges-final ul {
  list-style-type: none;
  padding-left: 0;
}
.purchase-confirmation .confirmation-footer .charges-final ul li:last-child {
  display: inline-block;
  margin-top: 1.6em;
  padding-top: 0.4em;
  border-top: solid 1px #dfdfdf;
}
@media (min-width: 540px) {
  .purchase-confirmation .performances .seats {
    display: block;
  }
  .purchase-confirmation .performances .seats li:first-child {
    grid-template-columns: 15% 10% 10% 15% 35% 15%;
    padding: 0.2em 0.4em;
  }
  .purchase-confirmation .performances .seats li.seat-header {
    font-size: 16px;
    text-align: left;
  }
  .purchase-confirmation .performances .seats li .seats-inner {
    padding-left: 0;
    display: block;
    overflow-x: visible;
  }
  .purchase-confirmation .performances .seats li .seats-inner li {
    grid-template-columns: 15% 10% 10% 15% 35% 15%;
    margin-bottom: 0.4em;
  }
}
@media (min-width: 768px) {
  .purchase-confirmation .performances {
    padding-left: 1.5em;
    padding-right: 1.8em;
  }
  .purchase-confirmation .performances .performance-header {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media (min-width: 840px) {
  .purchase-confirmation .confirm-header .purchase-info ul {
    flex-direction: row;
  }
  .purchase-confirmation .confirm-body {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .purchase-confirmation .confirm-body .customer-info {
    flex-basis: 36%;
    flex-direction: row;
    margin-right: 2%;
    padding-right: 1.6em;
    margin-left: 1.8em;
  }
  .purchase-confirmation .confirm-body .email-info {
    margin-right: 2em;
  }
  .purchase-confirmation .confirmation-footer .charges-final ul,
  .purchase-confirmation .confirmation-footer .payment-info ul {
    margin-left: 1.8em;
  }
}
@media print {
  /* Print =================================================== */
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  @page {
    margin: 0.5cm;
  }
  h2,
  h3 {
    orphans: 3;
    widows: 3;
    page-break-after: avoid;
  }
  p {
    orphans: 3;
    widows: 3;
  }
  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-size: 13px;
    font-size: 0.8125em;
    font-style: italic;
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after,
  a.site-logo[href]:after {
    content: "";
  }
  img {
    max-width: 100% !important;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  ::-webkit-input-placeholder {
    color: transparent;
  }
  :-moz-placeholder {
    color: transparent;
  }
  ::-moz-placeholder {
    color: transparent;
  }
  :-ms-input-placeholder {
    color: transparent;
  }
  nav,
  .btn,
  .btn-link {
    display: none;
  }
  .alert {
    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
