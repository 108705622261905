// Variables, mixins utilities 
@import "utils/variables.less";
@import "utils/mixins.less";
@import "utils/utilities.less";

// Reset and Base element styles
@import "base/base.less";

// Type Classes
@import "base/type.less";

// Image Classes
@import "base/images.less";

// Table Classes
@import "base/tables.less";

// Form Classes
@import "base/forms.less";

// Page Layout
@import "layout/page.less";
@import "layout/header.less";
@import "layout/footer.less";

// Navigation
@import "layout/nav.less";

// Simple Columns
@import "layout/simple-columns.less";

// Thumbnail Grid
@import "layout/thumbnail-grid.less";

// Modules and Components
@import "modules/alerts.less";
// @import "modules/labels.less";
// @import "modules/boxes.less";
@import "modules/banners.less";
@import "modules/buttons.less";
// @import "modules/media.less";
@import "modules/icons.less";
@import "modules/components.less";
@import "modules/calendar.less";
@import "modules/gallery.less";

// Wordpress WYSIWYG Editor Styles, turn on if using WP
@import "modules/wordpress.less";

// Template Specific
@import "pages/home.less";
@import "pages/event.less";
@import "pages/purchase.less";
@import "pages/subscriptions.less";
@import "pages/account.less";
@import "pages/confirmation.less";

// Print
@media print {
	@import "base/print.less";
}